import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from '../../../setup/services/setup-http.service';
import { PaymentsService } from '../../payments.service';

@Component({
  selector: 'app-payments-configuration',
  templateUrl: './payments-configuration.component.html',
  styleUrls: ['./payments-configuration.component.css']
})
export class PaymentsConfigurationComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  organisationsList: any;
  config: any;
  public emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private setupService: SetupService,
    private paymentService: PaymentsService
    ) { }

  ngOnInit(): void {
    this.config = {};
    this.loadOrganisationsList();
  }

  close() {
    this.bsModalRef.hide();
  }

  loadOrganisationsList() {
    this.setupService.getOrganisationsList().subscribe((res) => {
      this.organisationsList = res['Data']['Organisations'];
      if (this.organisationsList !== null && this.organisationsList.length > 0) {
        this.config.OrganisationId = this.organisationsList[0].Id.toString();
      }
    }, (err) => {
      if (err.status !== 403) {
        this.toastr.error(this.translate.instant('TOASTR.ORGANISATIONS.UNCAUGHT_GET_ORG_LIST_ERROR'));
      }
    });
  }

  saveConfig() {
    const config = {
      EnablePayments: this.config.OrganizationEmail ? true : false,
      PayPalEmail: this.config.OrganizationEmail
    };

    this.paymentService.updatePaymentsConfig(config, this.config.OrganisationId).subscribe((res) => {
      if (res['Status'] === 'Fail' || res['Status'] === 'Error') {
        this.toastr.error(this.translate.instant('TOASTR.INVOICE.UPDATE_CONFIG_FAIL'));
        return;
      }
      this.close();
      this.event.emit();
      this.toastr.success(this.translate.instant('TOASTR.INVOICE.UPDATE_CONFIG_SUCCESS'));
    }, (err) => {
      if (err.status !== 403) {
        this.toastr.error(this.translate.instant('TOASTR.INVOICE.UPDATE_CONFIG_FAIL'));
      }
    });
  }

}
