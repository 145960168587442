import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class changeOnwerService {
  private currentOrganisationIdSource = new BehaviorSubject<any>(null);
  private selectedUser = new BehaviorSubject<string>('');
  currentOrganisationId$ = this.currentOrganisationIdSource.asObservable();
  currentOrgId: number;

  constructor() { }

  setSelectedUser(userId: string) {
    this.selectedUser.next(userId);
  }

  getSelectedUser() {
    return this.selectedUser.asObservable();
  }
  setCurrentOrganisationId(id: any) {
    this.currentOrganisationIdSource.next(id);
  }

  getCurrentOrganisationId() {
    return this.currentOrganisationIdSource.value;
  }
}