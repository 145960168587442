import { Observable, EMPTY, throwError } from 'rxjs';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../common/constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { finalize, tap, catchError, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { http_error_codes as httpErrorCodes } from '../common/http_error_codes';
import { LoginService } from '../views/login/services/login-http.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  stopThisRequest: boolean = false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private loginService: LoginService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('Login')) this.stopThisRequest = false;

    if (this.stopThisRequest === true && !request.url.includes('Login')) {
      this.toastr.clear();
      this.ngxService.stopAll();
      this.stopThisRequest = false;
      this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'), '', { positionClass: 'toast-top-right' });
      setTimeout(function () {
        this.stopThisRequest = false;
      }, 3000);

      // return Observable.create(empty);
      return EMPTY;
    }

    // Remove double slashes from the URL
    const newUrl = { url: request.url.replace(/([^:]\/)\/+/g, '$1') };
    request = Object.assign(request, newUrl);

    const token: string = localStorage.getItem(constants.BEARER_TOKEN);

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', `BEARER ${token}`) });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    // this.ngxService.start();

    return next.handle(request).pipe(
      tap(evt => {
        // this.ngxService.stop();
      }),
      timeout(60000),
      catchError(error => {
        this.ngxService.stop();
        if (error.status === httpErrorCodes.TOKEN_EXPIRED) {
          this.ngxService.stopAll();
          this.router.navigate(['login']);
          this.loginService.clearLogin();
          const $tawkToAPI = window['Tawk_API'];
          if ($tawkToAPI) {
            const currentUserDetails = JSON.parse(localStorage.getItem(constants.CURRENT_USER_DETAILS)) || {};
            $tawkToAPI.setAttributes({
              'name': currentUserDetails.DisplayName || '',
              'email': currentUserDetails.Email || '',
              'LoginStatus': 'Not logged in'
            });
            $tawkToAPI.showWidget();
          }
          if (this.toastr.toasts.length === 0) {
            this.toastr.clear();
            this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'));
          } else {
            this.toastr.clear();
            this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'));
          }
          return EMPTY;
        } else if (error.name === "TimeoutError") {
          this.toastr.clear();
          this.toastr.error(this.translate.instant('TOASTR.TIMEOUT'));
          return EMPTY;
        }
        else {
          return throwError(error);
        }
        // return Observable.throw(error);
      })
    );

  }
}

