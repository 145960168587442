import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openApp() {
    let deepLink = "vetsoncloud://"
    window.location.href = deepLink.toString();
  }

  openPlayStore() {
    this.openApp();
    setTimeout(() => {
      window.location.href = "https://play.google.com/store/apps/details?id=com.osmosys.incidentreporter&hl=en";
    }, 3000);
  }

  openAppStore() {
    this.openApp();
    setTimeout(() => {
      window.location.href = "https://apps.apple.com/in/app/incident-reporter-365/id1513567560";
    }, 3000);
  }

}
