import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants'

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';
// import { OrganisationsModule } from '../organisations.module';

@Injectable()
export class OrganisationService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }
// saving this function for future references //
  // getOrganisationsList() {
  //   const organisation = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
  //   if (organisation !== undefined && organisation !== null) {
  //     return of(organisation);
  //   } else {
  //     const API_URL = `${this.env.apiUrl}/Organisations`;
  //     return this.httpClient.get(API_URL).map((data: any) => {
  //       localStorage.setItem(constants.STORAGE_KEYS.ORGANISATIONS, JSON.stringify(data));
  //       return data;
  //     }).catch((error: any) => {
  //       return Observable.throw(error);
  //     });
  //   }
  // }
  getOrganisationsList() {
    const API_URL = `${this.env.apiUrl}/Organisations`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getOrganisationById(id) {
    const API_URL = `${this.env.apiUrl}/Organisations/${id}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  getUserListForFilter(role:string = 'subscriber', orgId) {
    let API_URL = `${this.env.apiUrl}/Dashboard/PortalUsers?OrgId=${orgId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  getUserNames(userlist) {
    userlist = userlist.filter((item) => {
      // SHIFTER TO FASTER API-It doen't return User status
      // if (item.Status === 'Active') {
        if (item.DisplayName.trim() === '' && item.MobileNumber !== '') {
          item.DisplayName = item.CountryCode + ' ' + item.MobileNumber;
        }
        if (item.DisplayName === '' && item.MobileNumber === '') {
          item.DisplayName = item.UserUid;
        }
        return item;
      // }
      });
    return userlist;
  }
  getUserDetails(OrgID, userId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${OrgID}/Users/${userId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateVisibility(orgId, isVisible) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateVisiblity`;
    return this.httpClient.put(API_URL, {
      Visibility: isVisible
    }).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  updateLocation(orgId, isLocationEnabled) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateLocationFlag`;
    return this.httpClient.put(API_URL, {
      EnableLocation: isLocationEnabled
    }).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getDivisionByOrg(orgId) {
    const incidentDivisions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.DIVISIONS));
    if (incidentDivisions !== undefined && incidentDivisions !== null) {
      return of(incidentDivisions);
    } else {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  getIncidentSiteByOrg(orgId) {
    const incidentLocaltions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (incidentLocaltions !== undefined && incidentLocaltions !== null) {
      return of(incidentLocaltions);
    } else {
      const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
      return this.httpClient.get(API_URL).map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(data));
        return data;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
    }

  }

  updateCurrency(orgId, currency) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateCurrency`;
    return this.httpClient.put(API_URL, {Currency: currency}).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateGeoFence(orgId, geoFencingData) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateGeoFence`;
    return this.httpClient.put(API_URL, geoFencingData).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getIncidentLocations(orgId) {
    const incidentLocaltions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (incidentLocaltions !== undefined && incidentLocaltions !== null) {
      return of(incidentLocaltions);
    } else {
      const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
      return this.httpClient.get(API_URL).map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(data));
        return data;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
    }
  }

  createOraganisationSite(IncidentLocations, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
    return this.httpClient.post(API_URL, IncidentLocations).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  // upload org


  updateOrganisation(currentOrganisationId ,orgData){
    const API_URL = `${this.env.apiUrl}/Dashboard/UpdateOrganisations/${currentOrganisationId}`;
    return this.httpClient
    .put(API_URL, orgData)
    .map((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
   }

  //  upload icon

   updateOrganisationIcon(data, id, token) {
    const httpHeaders = new HttpHeaders()
      .append('Authorization', `BEARER ${token}`);
    const options = {
      headers: httpHeaders
    };
    const API_URL = `${this.env.apiUrl}/Organisations/${id}/UpdatePicture`;
    return this.httpClient.post(API_URL, data, options).map((data: any) => {
      return data['Data'];
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
}
