export const constants = {
  BEARER_TOKEN: 'INCIDENT_REPORTER_TOKEN',
  LOGGED_IN_USER_ID: 'INCIDENT_REPORTER_LOGGED_IN_USER_ID',
  REGISTERED_ORG_DETAILS: 'REGISTERED_ORG_DETAILS',
  CURRENT_USER_DETAILS: 'CURRENT_USER_DETAILS',
  CURRENT_SUBSCRIPTION: 'CURRENT_SUBSCRIPTION',
  HIGHEST_SUBSCRIPTION: 'HIGHEST_SUBSCRIPTION',
  PAYMENT_IN_PROGRESS_DETAILS: 'PAYMENT_IN_PROGRESS_DETAILS',
  PREFERRED_CURRENCY: 'PREFERRED_CURRENCY',
  IS_ORGANISATION_OWNER: 'IS_ORGANISATION_OWNER',
  INCIDENT_FILTER_PREFERENCES: 'INCIDENT_FILTER_PREFERENCES',
  HEATMAP_FILTER_PREFERENCES: 'HEATMAP_FILTER_PREFERENCES',
  FIELD_STAFF_MAP_FILTER_PREFERENCES: 'FIELD_STAFF_MAP_FILTER_PREFERENCES',
  DASHBOARD_FILTER_PREFERENCES: 'DASHBOARD_FILTER_PREFERENCES',
  APPOINTMENT_FILTER_PREFERENCES: 'APPOINTMENT_FILTER_PREFERENCES',
  PAYMENT_FILETER_PREFERENCES: 'PAYMENT_FILTER_PREFERENCES',
  USERS_FILTER_PREFERENCES: 'USERS_FILTER_PREFERENCES',
  INCIDENT_LIST_FOR_NAV: 'INCIDENT_LIST_FOR_NAV',
  INCIDENTS_API_CURRENT_FILTER: 'INCIDENTS_API_CURRENT_FILTER',
  INVENTORY_FILETER_PREFERENCES: 'INVENTORY_FILETER_PREFERENCES',
  CLINIC_ID: 'CLINIC_ID',
  APP_LANGUAGE: 'APP_LANGUAGE',
  ICON_FILE_SIZE: 300000,
  ORG_OWNER_USER_ID: 'ORG_OWNER_USER_ID',
  DATA_TABLE_STATES: {
    INCIDENTS: 'DATA_TABLE_STATE_INCIDENTS',
    APPOINTMENTS: 'DATA_TABLE_STATE_APPOINTMENTS',
    USERS: 'DATA_TABLE_STATE_USERS',
    PETS: 'DATA_TABLE_STATE_PETS',
    PAYMENTS: 'DATA_TABLE_STATE_PAYMENTS',
    INVENTORY: 'DATA_TABLE_STATE_INVENTORY',
    DASHBOARD: 'DATA_TABLE_STATE_DASHBOARD',
    NOTIFICATIONS: 'DATA_TABLE_STATE_NOTIFICATIONS',
    BILLING: 'DATA_TABLE_STATE_BILLING',
    PETCARE_STAFF: 'DATA_TABLE_STATE_PET_CARE_STAFF',
    SETUP: 'DATA_TABLE_STATE_SETUP'
  },
  HEATMAP_DEFAULTS: {
    ZOOM_LEVEL: 13
  },
  FIELD_STAFF_MAP_DEFAULTS: {
    ZOOM_LEVEL: 13
  },
  QUESTION_TYPES: {
    FREE_TEXT: 'FREETEXT',
    MCQ: 'MCQ'
  },
  GEOCODING_URL: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  MAPS_API_KEY: 'AIzaSyAJh0tQ71dPRiLjmUbhfVP-HQHVdiDOZYE',
  SUPPORTED_COUNTRIES: [
    {
      dialCode: '61',
      name: 'Australia',
      iso2: 'au'
    },
    {
      dialCode: '43',
      name: 'Austria (Österreich)',
      iso2: 'at'
    },
    {
      dialCode: '32',
      name: 'Belgium (België)',
      iso2: 'be'
    },
    {
      dialCode: '86',
      name: 'China (中国)',
      iso2: 'cn'
    },
    {
      dialCode: '45',
      name: 'Denmark (Danmark)',
      iso2: 'dk'
    },
    {
      dialCode: '358',
      name: 'Finland (Suomi)',
      iso2: 'fi'
    },
    {
      dialCode: '33',
      name: 'France',
      iso2: 'fr'
    },
    {
      dialCode: '49',
      name: 'Germany (Deutschland)',
      iso2: 'de'
    },
    {
      dialCode: '30',
      name: 'Greece (Ελλάδα)',
      iso2: 'gr'
    },
    {
      dialCode: '36',
      name: 'Hungary (Magyarország)',
      iso2: 'hu'
    },
    {
      dialCode: '91',
      name: 'India (भारत)',
      iso2: 'in'
    },
    {
      dialCode: '353',
      name: 'Ireland',
      iso2: 'ie'
    },
    {
      dialCode: '39',
      name: 'Italy (Italia)',
      iso2: 'it'
    },
    {
      dialCode: '356',
      name: 'Malta',
      iso2: 'mt'
    },
    {
      dialCode: '31',
      name: 'Netherlands (Nederland)',
      iso2: 'nl'
    },
    {
      dialCode: '47',
      name: 'Norway (Norge)',
      iso2: 'no'
    },
    {
      dialCode: '48',
      name: 'Poland (Polska)',
      iso2: 'pl'
    },
    {
      dialCode: '351',
      name: 'Portugal',
      iso2: 'pt'
    },
    {
      dialCode: '7',
      name: 'Russia (Россия)',
      iso2: 'ru'
    },
    {
      dialCode: '966',
      name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
      iso2: 'sa'
    },
    {
      dialCode: '34',
      name: 'Spain (España)',
      iso2: 'es'
    },
    {
      dialCode: '46',
      name: 'Sweden (Sverige)',
      iso2: 'se'
    },
    {
      dialCode: '971',
      name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
      iso2: 'ae'
    },
    {
      dialCode: '44',
      name: 'United Kingdom',
      iso2: 'gb'
    },
    {
      dialCode: '1',
      name: 'United States',
      iso2: 'us'
    }
  ],
  packageMonths: [
    {
      name: '1 month',
      value: 1
    },
    {
      name: '2 months',
      value: 2
    },
    {
      name: '3 months',
      value: 3
    },
    {
      name: '4 months',
      value: 4
    },
    {
      name: '5 months',
      value: 5
    },
    {
      name: '6 months',
      value: 6
    },
    {
      name: '7 months',
      value: 7
    },
    {
      name: '8 months',
      value: 8
    },
    {
      name: '9 months',
      value: 9
    },
    {
      name: '10 months',
      value: 10
    },
    {
      name: '11 months',
      value: 11
    },
    {
      name: '1 year',
      value: 12
    }
  ],
  currency: 'USD',
  currencyOptions: [{
    title: 'INR (Only for Indian customers)',
    value: 'INR'
  }, {
    title: 'USD',
    value: 'USD'
  }, {
    title: 'GBP',
    value: 'GBP'
  }],
  taxPercentages: {
    INR: 18,
    USD: 0,
    GBP: 0
  },
  lookp: {
    PET_FRIENDLINESS: 1001,
    PET_REGULAR_DIET: 1002,
    PET_CATEGORY: 1003,
    PET_BREED_DOG: 1004,
    PET_BREED_CAT: 1005,
    PET_BREED_BIRD: 1006,
    PET_BREED_RABBIT: 1007,
    PET_BREED_HORSE: 1008,
  },
  STORAGE_KEYS: {
    ORGANISATIONS: 'ORGANISATIONS',
    COUNTRIES: 'COUNTRIES',
    PET_TYPES: 'PET_TYPES',
    GENDER_TYPES: 'GENDER_TYPES',
    DIVISIONS: 'DIVISIONS',
    INCIDENT_LOCATIONS: 'INCIDENT_LOCATIONS',
    LOOKUP: 'LOOKUP'
  },
  DOCUMENT_SIZE: {
    MAX_SIZE_IN_BYTES: 15728640, // 15MB
    IMAGE_SIZE_IN_BYTES: 1048576 
  },
  notificationTypeList: [
    {
      Id: undefined,
      Title: 'Select notification type'
    },
    {
      Id: 1,
      Title: 'Push notification'
    },
    {
      Id: 3,
      Title: 'WhatsApp'
    },
    {
      Id: 4,
      Title: 'SMS (text message)'
    }
  ],
  notificationTypeListSendNotifications : [
    {
      Id: undefined,
      Title: 'Select notification type'
    },
    {
      Id: 1,
      Title: 'Push notification'
    },
    {
      Id: 3,
      Title: 'WhatsApp'
    }
],

  userGroupList: [
    {
      Id: undefined,
      Title: 'Select users group'
    },
    {
      Id: 1,
      Title: 'All users'
    },
    {
      Id: 2,
      Title: 'Mobile app users'
    },
    {
      Id: 3,
      Title: 'Non app users'
    }
  ],
  fileExtensionsTypes: [
    {
      ext: 'msword'
    },
    {
      ext: 'doc'
    },
    {
      ext: 'DOC'
    },
    {
      ext: 'docx'
    },
    {
      ext: 'DOCX'
    },
    {
      ext: 'PDF'
    },
    {
      ext: 'pdf'
    },
    {
      ext: 'xls'
    },
    {
      ext: 'XLS'
    },
    {
      ext: 'xlsx'
    },
    {
      ext: 'XLSX'
    },
    {
      ext: 'ppt'
    },
    {
      ext: 'PPT'
    },
    {
      ext: 'pptx'
    },
    {
      ext: 'PPTX'
    },
    {
      ext: 'docm'
    },
    {
      ext: 'DOCM'
    },
    {
      ext: 'dotm'
    },
    {
      ext: 'DOTM'
    },
    {
      ext: 'xlsm'
    },
    {
      ext: 'XLSM'
    },
    {
      ext: 'XLTM'
    },
    {
      ext: 'xltm'
    },
    {
      ext: 'xlam'
    },
    {
      ext: 'XLAM'
    },
    {
      ext: 'PPTM'
    },
    {
      ext: 'pptm'
    },
    {
      ext: 'potm'
    },
    {
      ext: 'POTM'
    },
    {
      ext: 'PPAM'
    },
    {
      ext: 'ppam'
    },
    {
      ext: 'sldm'
    },
    {
      ext: 'SLDM'
    },
    {
      ext: 'odt'
    },
    {
      ext: 'ODT'
    },
    {
      ext: 'ods'
    },
    {
      ext: 'ODS'
    },
    {
      ext: 'txt'
    },
    {
      ext: 'TXT'
    },
    {
      ext: 'gif'
    },
    {
      ext: 'GIF'
    },
    {
      ext: 'tiff'
    },
    {
      ext: 'TIFF'
    },
    {
      ext: 'rar'
    },
    {
      ext: 'RAR'
    },
    {
      ext: 'zip'
    },
    {
      ext: 'csv'
    },
    {
      ext: 'CSV'
    },
    {
      ext: 'ODP'
    },
    {
      ext: 'odp'
    },
    {
      ext: 'dot'
    },
    {
      ext: 'DOT'
    },
    {
      ext: 'dotx'
    },
    {
      ext: 'DOTX'
    },
    {
      ext: 'XL'
    },
    {
      ext: 'xl'
    },
    {
      ext: 'xlsb'
    },
    {
      ext: 'XLSB'
    },
    {
      ext: 'xltx'
    },
    {
      ext: 'XLTX'
    },
    {
      ext: 'xlt'
    },
    {
      ext: 'XLT'
    },
    {
      ext: 'xla'
    },
    {
      ext: 'XLA'
    },
    {
      ext: 'xlm'
    },
    {
      ext: 'XLM'
    },
    {
      ext: 'xlw'
    },
    {
      ext: 'XLW'
    },
    {
      ext: 'uxdc'
    },
    {
      ext: 'UXDC'
    },
    {
      ext: 'ppsx'
    },
    {
      ext: 'PPSX'
    },
    {
      ext: 'pps'
    },
    {
      ext: 'PPS'
    },
    {
      ext: 'ppsm'
    },
    {
      ext: 'PPSM'
    },
    {
      ext: 'potx'
    },
    {
      ext: 'POTX'
    },
    {
      ext: 'pot'
    },
    {
      ext: 'POT'
    },
    {
      ext: 'TIF'
    },
    {
      ext: 'tif'
    },
    {
      ext: 'pcx'
    },
    {
      ext: 'PCX'
    },
    {
      ext: 'psd'
    },
    {
      ext: 'PSD'
    },
    {
      ext: 'CUT'
    },
    {
      ext: 'cut'
    },
    {
      ext: 'DDS'
    },
    {
      ext: 'dds'
    },
    {
      ext: 'ICO'
    },
    {
      ext: 'ico'
    },
    {
      ext: 'PBM'
    },
    {
      ext: 'pbm'
    },
    {
      ext: 'PGM'
    },
    {
      ext: 'TXT'
    },
    {
      ext: 'parquet'
    },
    {
      ext: 'PARQUET'
    },
    {
      ext: 'xml'
    },
    {
      ext: 'XML'
    }
  ],
  imageTypes: [
    {
      Id: 0,
      text: 'None'
    },
    {
      Id: 2,
      text: 'File',
    }
  ],
  validImageExtensions: ['apng', 'png', 'avif', 'jpg', 'gif', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp'],
  validVideoExtensions: ['mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm', 'wmv', 'mpeg', '3gp', '3gpp', 'flv', 'avi', 'mkv'],
  validAudioExtensions: ['3ga', 'aac', 'amr', 'aiff', 'ape', 'arf', 'gp4', 'gp5', 'mp3', 'm4a', 'aac', 'mpeg', 'wav', 'ogg', 'au', 'snd', 'mid', 'rmi', 'aif', 'aifc', 'm3u', 'ra', 'ram', 'flac', 'wma'],
  validFileExtensions: ['msword', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'docm', 'dotm', 'xlsm', 'xltm', 'xlam', 'pptm', 'potm', 'ppam', 'sldm', 'odt', 'ods', 'txt', 'gif', 'tiff', 'rar', 'zip', 'csv', 'odp', 'dot', 'dotx', 'xl', 'xlsb', 'xltx', 'xlt', 'xla', 'xlm', 'xlw', 'uxdc', 'ppsx', 'pps', 'ppsm', 'potx', 'pot', 'tif', 'pcx', 'psd', 'cut', 'dds', 'ico', 'pbm', 'parquet', 'xml'],
};
