import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable } from 'rxjs';
import { constants } from '../../../common/constants';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  userRegister(register) {
    const API_URL = `${this.env.apiUrl}/Register`;
    return this.httpClient.post(API_URL, register).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getTermsConditions(lang: any) {
    const API_URL = `${this.env.apiUrl}/Resources/terms-and-conditions-${lang}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data['Data']['Resource']['TermsAndConditions'];
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  uploadOrganisationIcon(formData, id, token) {
    const httpHeaders = new HttpHeaders()
      .append('Authorization', `BEARER ${token}`);
    const options = {
      headers: httpHeaders
    };
    const API_URL = `${this.env.apiUrl}/Organisations/${id}/UpdatePicture`;
    return this.httpClient.post(API_URL, formData, options).map((data: any) => {
      return data['Data'];
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getCountryList() {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getLatLngByLocation(location): Observable<any> {
    const Url = constants.GEOCODING_URL + location + '&key=' + constants.MAPS_API_KEY;
    return this.httpClient.get(Url).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getLanguageList() {
    const API_URL = `${this.env.apiUrl}/Languages`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

}
