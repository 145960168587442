import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../../../env.service';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(
    private env: EnvService,
    private httpClient: HttpClient
  ) { }

  validateCoupon(CouponCode) {
    const API_URL = `${this.env.apiUrl}/Coupons`;
    return this.httpClient.post(API_URL, CouponCode).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
}
