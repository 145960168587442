import { Component, AfterViewInit, OnInit, TemplateRef, Input} from '@angular/core';
import { sideNavItems } from '../../_nav';
import { Router } from '@angular/router';
import { constants } from '../../common/constants';
import { SPINNER } from 'ngx-ui-loader';
import { AclService } from 'ng2-acl/dist';
import { LoginService } from '../../views/login/services/login-http.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvService } from '../../env.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { CreateAppointmentComponent } from '../../views/appointments/modals/create-appointment/create-appointment.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements AfterViewInit, OnInit {
  isLoading: boolean;
  subscription: Subscription;
  public sidebarMinimized = false;
  public navItems = [];
  public spinners = SPINNER;
  public version: any;
  modalRef: BsModalRef;
  createModelRef: any;

  year: number = moment().get('year');
  determinateValue: number;

  constructor(
    private router: Router,
    private aclService: AclService,
    private loginService: LoginService,
    private translate: TranslateService,
    private env: EnvService,
    private modalService: BsModalService,
  ) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.determinateValue += 1;
      if (this.determinateValue > 100) {
      this.determinateValue = 10;
      }
      }, 100, 0, true);
    this.version = this.env.version;
    const allNavItems = JSON.parse(JSON.stringify(sideNavItems));
    if (!this.aclService.can('dashboard')) {
      const dashboardIndex = allNavItems.findIndex(x => x.url === '/dashboard');
      if (dashboardIndex !== -1) {
        allNavItems.splice(dashboardIndex, 1);
      }
    }
    if (!this.aclService.can('heatMap')) {
      const heatMapIndex = allNavItems.findIndex(x => x.url === '/issues/heatmap');
      if (heatMapIndex !== -1) {
        allNavItems.splice(heatMapIndex, 1);
      }
    }
    const isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    if (!isOrgOwner || isOrgOwner === 'false' || isOrgOwner === 'undefined') {
      const billingIndex = allNavItems.findIndex(x => x.url === '/billing/list');
      if (billingIndex !== -1) {
        allNavItems.splice(billingIndex, 1);
      }
    }
    allNavItems.forEach(navItem => {
      if (navItem.name) {
        navItem.name = this.translate.instant(navItem.name);
      }
    });
    this.navItems = allNavItems;
  }

  ngAfterViewInit() {
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.loginService.clearLogin();
    if (this.aclService.getRoles().length > 0) {
      this.aclService.detachRole(this.aclService.getRoles()[0]);
    }
    const $tawkToAPI = window['Tawk_API'];
    if ($tawkToAPI) {
      this.setChatDetails($tawkToAPI, false);
      $tawkToAPI.showWidget();
    }
    this.router.navigate(['login']);
  }

  setChatDetails($tawkToAPI, isLoggedIn) {
    const currentUserDetails = JSON.parse(localStorage.getItem(constants.CURRENT_USER_DETAILS)) || {};
    $tawkToAPI.setAttributes({
      'name'  : currentUserDetails.DisplayName || '',
      'email' : currentUserDetails.Email || '',
      'LoginStatus' : isLoggedIn ? 'Logged in with ' + currentUserDetails.Email : 'Not logged in'
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
 }

 openCreateAppointment() {
  this.createModelRef = this.modalService.show(CreateAppointmentComponent, {
    backdrop: 'static',
    keyboard: false
  });
  this.createModelRef.content.event.subscribe(res => {
    this.router
    .navigateByUrl("/", { skipLocationChange: true })
    .then(() =>
      this.router.navigate([
        "appointments/list"
      ])
    );
  });
}

openCreateIncident() {
  this.router.navigate([`issues/new`]);
}
}
