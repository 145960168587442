import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { SPINNER } from "ngx-ui-loader";
import { PaymentService } from "../billing/services/payment.service";
import { TranslateService } from "@ngx-translate/core";
import { constants } from "../../common/constants";
import { RegisterService } from "../register/services/register-http.service";
import { EnvService } from "../../env.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  user: any = {};
  public spinners = SPINNER;
  public selectedPackagePriceDetails;
  countryList: any = [];
  languageList: any = [];
  timeSlots: any;
  youTubeUrl: any;
  selectedLang: any;
  supportedLangs: any;
  refreshContent: any = false;
  year: number = moment().get("year");

  constructor(
    public auth: AuthService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private registerService: RegisterService,
    private env: EnvService,
    public sanitizer: DomSanitizer,
    public router: Router
  ) {}

  ngOnInit(): void {
    let isOnline = window.navigator.onLine;
    if(isOnline == false) {
      this.toastr.error(this.translate.instant('COMMON.NO_INTERNET_CONNECTION_MSG'));
    }
    this.youTubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.env.YouTubeUrl
    );
    this.timeSlots = this.env.demoSlots;
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    this.selectedLang = userLang ? userLang : this.env.defaultLang;
    window["hideLoader"]();
    this.initLiveChat();
    this.getCountryList();
    this.getLanguageList();
    this.user.Country = "";
    this.user.Slot = "";
    if (window.location.href.includes('home#')) {
    const id =   window.location.href.replace(/(.*)#(\/home)#(.*)/g, '$3');
      if (id) {
        setTimeout(() => {
          $('html, body').animate({
            scrollTop: $('#' + id).offset().top
          }, 500);
        }, 1000);
      }
    }
  }

  goToHome () {
    if (window.location.href.includes('home#')) {
      window.location.href = window.location.href.replace(/(.*)\/(home)#(.*)/g, '$1/$2');
      console.log(window.location.href = window.location.href);
    }
  }

  submitDemoRequest() {
    this.auth.submitDemoRequest(this.user).subscribe(
      (res) => {
        if (res["Status"] === "Fail") {
          this.catchLoginErrors(res);
          return;
        }
        this.user = {};
        this.user.Country = "";
        this.user.Slot = "";
        this.toastr.success("Your request has been submitted successfully");
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error(
          "There was an error while submitting your request, please try again"
          );
        }
      }
    );
  }

  onBuyPkg(pkg) {
    if (pkg.NumOfMonths === undefined) {
      this.selectedPackagePriceDetails = null;
      this.auth.activeForm = "registration";
      this.auth.buyPackage = this.selectedPackagePriceDetails;
      window.scrollTo(0, 0);
      return;
    }
    this.paymentService.getPriceDetails(pkg.Id, pkg.NumOfMonths).subscribe(
      (res) => {
        this.selectedPackagePriceDetails = res["Data"]["PriceDetails"];
        this.selectedPackagePriceDetails.PricePerMonthUSD =
          pkg.PricePerMonthUSD;
        this.selectedPackagePriceDetails.PricePerMonthINR =
          pkg.PricePerMonthINR;
        this.auth.activeForm = "registration";
        this.auth.buyPackage = this.selectedPackagePriceDetails;
        window.scrollTo(0, 0);
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error(
            this.translate.instant(
             "LABELS.BILLING_SUMMARY.MESSAGES.GET_PRICE_ERROR"
            )
          );
        }
      }
    );
  }

  catchLoginErrors(res) {
    this.toastr.error(res.Message);
  }

  getCountryList() {
    this.registerService.getCountryList().subscribe(
      (res) => {
        if (res["Status"] === "Fail" || res["Status"] === "Error") {
          this.catchLoginErrors(res);
          return;
        }
        this.countryList = res["Data"]["Countries"];
      },
      (err) => {}
    );
  }

  getLanguageList() {
    this.registerService.getLanguageList().subscribe(
      (res) => {
        if (res["Status"] === "Fail" || res["Status"] === "Error") {
          this.catchLoginErrors(res);
          return;
        }
        this.languageList = res["Data"]["Languages"];
      },
      (err) => {}
    );
  }

  initLiveChat() {
    let $tawkToAPI = window["Tawk_API"];
    if ($tawkToAPI) {
      $tawkToAPI.showWidget();
      return;
    }
    (function () {
      const s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5f2ac6e35c885a1b7fb6928d/default";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
      const interval = setInterval(() => {
        $tawkToAPI = window["Tawk_API"];
        if ($tawkToAPI) {
          if (localStorage.getItem(constants.BEARER_TOKEN)) {
            if ($tawkToAPI.isChatHidden()) {
              clearInterval(interval);
            } else {
              $tawkToAPI.hideWidget();
            }
          } else {
            clearInterval(interval);
          }
        }
      }, 1000);
    })();
  }

  ngOnDestroy() {
    const $tawkToAPI = window["Tawk_API"];
    if ($tawkToAPI) {
      try {
        $tawkToAPI.hideWidget();
      } catch {
        // TO-DO Investigate and fix this issue
        console.log('Error');
      }
    }
  }

  onLangChange() {
    this.refreshContent = true;
    this.translate.setDefaultLang(this.selectedLang);
    this.translate.use(this.selectedLang).subscribe(() => {
      localStorage.setItem(constants.APP_LANGUAGE, this.selectedLang);
      setTimeout(() => {
        this.refreshContent = false;
      }, 1000);
    });
  }

  onBuyClick() {
      this.auth.activeForm = "registration";
      window.scrollTo(0, 0);
      return;
  }

  goToLoginPage() {
    this.auth.activeForm = "login";
    this.router.navigate(['login']);
  }

}
