import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServerStatusService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getServerStatus() {
    const API_URL = `${this.env.apiUrl}/Health`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
}

