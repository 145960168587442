import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpClient: HttpClient, private env: EnvService) {}

  getNotificationList(
    params,
    pageNumber,
    dtCallback,
    orgId,
    searchTerm,
    sortingColumn,
    sortingOrder
  ) {
    const API_URL = `${this.env.apiUrl}/Notifications?PageNumber=${pageNumber}&PageLimit=${params.length}&OrgId=${orgId}&SortingColumn=${sortingColumn}&SortingOrder=${sortingOrder}&SearchTerm=${searchTerm}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        dtCallback({
          recordsTotal: data.Data.TotalNumberOfRecords || 0,
          recordsFiltered: data.Data.TotalNumberOfRecords || 0,
          data: [],
        });
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  sendNotification(notification, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Notifications`;
    return this.httpClient
      .post(API_URL, notification)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getAppUserListFast(orgId) {
    const API_URL = `${this.env.apiUrl}/AllUsers?OrgId=${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  exportNotifications() {
    const API_URL = `${this.env.apiUrl}/Notifications?Export=true`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  downloadFile(filePath) {
    let a = document.createElement('a');
    a.href = filePath;
    a.download = filePath.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteNotification(id,userNotificationId) {
    const API_URL = `${this.env.apiUrl}/Notifications/${userNotificationId}`
    return this.httpClient.delete(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  sendNotificationById(id) {
    const API_URL = `${this.env.apiUrl}/Notifications/${id}/send`;
    return this.httpClient.post(API_URL , {}).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  getSmsOption() {
    const API_URL = `${this.env.apiUrl}/SMS`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  
  getWhatsappTemplate() {
    const API_URL = `${this.env.apiUrl}/Notifications/WhatsappTemplates`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      }
    );
  }
}
