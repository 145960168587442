import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AclRedirection } from '../../app.redirection';
import { constants } from '../../common/constants';

@Injectable()
export class AclBillingResolver implements Resolve<any> {
  constructor(
    private router: Router, private aclRedirection: AclRedirection,
  ) { }

  resolve(): Observable<any> {
    const isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    if (isOrgOwner === 'true') {
      // Has proper permissions
      return of(true);
    } else {
      // Does not have permission
      this.aclRedirection.redirectTo('Unauthorized');
    }
  }
}
