import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RegisterService } from "./services/register-http.service";
import { Register } from "./models/register.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { SPINNER } from "ngx-ui-loader";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TermsAndCondtionsComponent } from "./modals/terms-and-condtions/terms-and-condtions.component";
import { constants } from "../../common/constants";
import { http_error_codes } from "../../common/http_error_codes";
import { AuthService } from "../../auth/auth.service";
import { CommonModalDialogComponent } from "../../components/modal-dialog/common-modal-dialog.component";

@Component({
  selector: "app-registration-component",
  templateUrl: "register.component.html",
})
export class RegisterComponent implements OnInit {
  public register: Register;
  public spinners = SPINNER;
  public registrationInProgress: boolean = false;
  selectedFile: File = null;
  isAgreed: any;
  supportedCountryList: any = [];
  distance = 32186.9; // 20 miles in meters
  directions = 359;
  showGeoCodeNotAvailable: any = false;
  public currencies = constants.currencyOptions;
  private modalRef: BsModalRef;
  referralcodeInvalid: boolean = false;
  ReferralCode: any;

  constructor(
    private router: Router,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.register = new Register();
    this.register.IsClosedOrganisation = false;
    this.spinners = SPINNER;
    this.registrationInProgress = false;
    this.getCountryList();
    this.initAutocomplete();
    const countryCode = this.currencies.filter(item => {
      if (item.value === "INR") {
        this.register.Currency = item.value;
        return this.register.Currency;
      }
    });
  }

  onFileSelected(event) {
    let selectedFile = (event.target as HTMLInputElement).files[0];
    if (selectedFile.size > constants.ICON_FILE_SIZE) {
      this.toastr.error(this.translate.instant("TOASTR.REGISTER.FILE_SIZE_EXCEED"));
      this.selectedFile = null;
    } else {
      this.selectedFile = selectedFile;
    }
  }

  openModalPopUp(register) {
    const initialSate = {
      title: this.translate.instant('COMMON.CUSTOM_ALERT.REFERRAL_CODE'),
      subText: this.translate.instant('COMMON.CUSTOM_ALERT.REFERRAL_SUB_TEXT'),
      yesText: this.translate.instant('COMMON.YES'),
      noText: this.translate.instant('COMMON.NO')
    };

    this.modalRef = this.modalService.show(CommonModalDialogComponent, { initialState: initialSate });
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        register.ReferralCode = "";
        this.userRegister();
      }
      this.registrationInProgress = false;
    });
  }

  userRegister() {
    if (
      (this.register.MobileNumber === null ||
        this.register.MobileNumber.toString() === "") &&
      this.register.CountryCode !== ""
    ) {
      this.toastr.info(this.translate.instant("TOASTR.REGISTER.VALIDATION.MOBILE_NUMBER_REQUIRED"));
      return;
    }
    if (
      this.register.MobileNumber !== null &&
      this.register.MobileNumber.toString() !== "" &&
      this.register.CountryCode === ""
    ) {
      this.toastr.info(this.translate.instant("TOASTR.REGISTER.VALIDATION.COUNTRY_CODE_REQUIRED"));
      return;
    }
    if (
      this.register.MobileNumber !== null &&
      this.register.MobileNumber.toString() !== "" &&
      this.register.Currency === ""
    ) {
      this.toastr.info(this.translate.instant("TOASTR.REGISTER.VALIDATION.CURRENCY_REQUIRED"));
      return;
    }
    this.registrationInProgress = true;
    this.registerService.userRegister(this.register).subscribe(
      async (res) => {
        if (res["Status"] === "Fail") {
          if (res['Data']['ReferralCode'] == "Invalid ReferralCode.") {
            this.openModalPopUp(this.register);
          } else if (res['Data']) {
            let errMessage = Object.values(res['Data'])[0];
            this.toastr.error(errMessage.toString());
            this.catchErrors(res);
            return;
          }
          return;
        }

        this.register = res["Data"]["Organisation"];
        const token = res["Data"]["Organisation"]["Token"];
        if (this.selectedFile) {
          await this.uploadOrganisationIcon(this.register["Id"], token);
        }
        this.register = res["Data"]["Organisation"];
        this.getOrganisationDetails(
          this.register["OrganisationUid"],
          this.register["OrganisationName"],
          this.register["Email"],
          this.register["IsCloseOrganisation"],
          this.register["Id"]
        );
      },
      (err) => {
        this.registrationInProgress = false;
        if (err.status === http_error_codes.API_CALL_LIMIT_EXCEEDED) {
          this.toastr.error(
            this.translate.instant("COMMON.API_CALL_LIMIT_EXCEEDED")
          );
        } else {
          // TODO : Change error message once they are standardised by the backend.
          this.toastr.error(this.translate.instant("TOASTR.PORTAL_USERS.USER_EXISTS"));
        }
      }
    );
  }

  uploadOrganisationIcon(orgId, token) {
    const formData = new FormData();
    formData.append("OrgIcon", this.selectedFile);
    this.registerService
      .uploadOrganisationIcon(formData, orgId, token)
      .subscribe(
        (res) => {
          if (res["Status"] === "Fail") {
            this.catchErrors(res);
            return;
          }
        },
        (err) => {
          this.registrationInProgress = false;
          this.toastr.warning("TOASTR.REGISTER.ORGANISATION_CREATE_ERROR_ICON");
        }
      );
  }

  catchErrors(err) {
    if (err["ErrorCode"] === "USER_EXISTS") {
      this.toastr.error(this.translate.instant("TOASTR.REGISTER.USER_EXISTS"));
    } else if (err["ErrorCode"] === "USER_HAS_PORTAL_ACCESS") {
      this.toastr.error(this.translate.instant("TOASTR.REGISTER.USER_HAS_PORTAL_ACCESS"));
    } else {
      const errorMessage = err
        ? err.Message
          ? err.Message
          : err.ErrorCode
        : "There was an uncaught error while processing your request, please try again";
      this.toastr.error(errorMessage);
    }
    this.registrationInProgress = false;
  }

  getOrganisationDetails(
    uniqueId,
    organisationName,
    email,
    isClosedOrganisation,
    organisationId
  ) {
    localStorage.setItem(
      constants.REGISTERED_ORG_DETAILS,
      JSON.stringify({
        uniqueId,
        organisationName,
        email,
        isClosedOrganisation,
        organisationId,
      })
    );

    if (this.auth.buyPackage === null || this.auth.buyPackage.PackageId === 1) {
      this.auth.activeForm = "successMessageComponent";
    } else {
      localStorage.setItem(constants.BEARER_TOKEN, this.register["Token"]);
      this.auth.activeForm = "billingSummaryComponent";
    }
  }

  showTermsAndConditions() {
    this.modalService.show(TermsAndCondtionsComponent);
  }

  showLogin() {
    this.auth.activeForm = "login";
  }

  showHomePage() {
    this.auth.activeForm = "login";
    this.router.navigate(["/home"]);
  }

  getCountryList() {
    let countryPinCode = null;
    this.registerService.getCountryList().subscribe(
      (res) => {
        if (res["Status"] === "Fail") {
          this.catchErrors(res);
          return;
        }
        this.supportedCountryList = res["Data"]["Countries"];
        const countryCode = this.supportedCountryList.filter(item => {
          if (item.PhoneCode === "+91") {
            countryPinCode = item.PhoneCode;
            return countryPinCode;
          }
        });
        this.register.CountryCode = countryPinCode;
      },
      (err) => { }
    );
  }

  initAutocomplete() {
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    const markers: google.maps.Marker[] = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      this.register.Center = {};
      this.register.GeoFence = [];

      if (places.length === 0) {
        this.showGeoCodeNotAvailable = true;
        return;
      }

      const resultPlace = places[0];
      this.register.Address = resultPlace.formatted_address;
      if (!resultPlace.geometry) {
        this.registerService
          .getLatLngByLocation(resultPlace.formatted_address)
          .subscribe(
            (res) => {
              if (res["status"] === "OK") {
                this.register.Center = {
                  Lat: res["results"][0].geometry.location.lat,
                  Lng: res["results"][0].geometry.location.lng,
                };
                this.register.Latitude = this.register.Center.Lat;
                this.register.Longitude = this.register.Center.Lng;
                this.register.GeoFence = this.generateGeoJSONCircle(
                  new google.maps.LatLng(
                    this.register.Center.Lat,
                    this.register.Center.Lng
                  ),
                  this.distance,
                  this.directions
                );
              } else {
                this.showGeoCodeNotAvailable = true;
              }
            },
            (err) => {
              this.showGeoCodeNotAvailable = true;
            }
          );
      } else {
        this.register.Center = {
          Lat: resultPlace.geometry.location.lat(),
          Lng: resultPlace.geometry.location.lng(),
        };
        this.register.Latitude = this.register.Center.Lat;
        this.register.Longitude = this.register.Center.Lng;
        this.register.GeoFence = this.generateGeoJSONCircle(
          resultPlace.geometry.location,
          this.distance,
          this.directions
        );
      }
    });
  }

  generateGeoJSONCircle(center, distance, numSides) {
    this.showGeoCodeNotAvailable = false;
    const points = [],
      degreeStep = 360 / numSides;

    for (let i = 0; i < numSides; i++) {
      const gPos = google.maps.geometry.spherical.computeOffset(
        center,
        distance,
        degreeStep * i
      );
      points.push({ lng: gPos.lng(), lat: gPos.lat() });
    }

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);

    return points;
  }
}
