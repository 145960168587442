import { constants } from '../../../common/constants';

export class Register {
    public CountryCode: string;
    public MobileNumber: number;
    public FirstName: string;
    public LastName: string;
    public OrganisationName: string;
    public IsClosedOrganisation: boolean;
    public Email: string;
    public Address: string;
    public Center: any;
    public GeoFence: any;
    public ZoomLevel: any;
    public Latitude: any;
    public Longitude: any;
    public Currency: any;
    public ReferralCode: any;

    constructor() {
        this.CountryCode = '';
        this.MobileNumber = null;
        this.FirstName = '';
        this.LastName = '';
        this.OrganisationName = '';
        this.IsClosedOrganisation = false;
        this.Email = '';
        this.ZoomLevel = constants.HEATMAP_DEFAULTS.ZOOM_LEVEL;
        this.Currency = null;
    }
}
