import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EnvService } from '../../env.service';
import { changeOnwerService } from './changeOwnerService';
import { OrganisationService } from '../organisations/services/organisation-http.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../common/constants';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  styleUrls: ['./change-owner.component.css']
})
export class ChangeOwnerComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  modalRef: BsModalRef;
  portalUsers: any[];
  currentOrgId: any;
  selectedUserId: string;
  public selectedOwner: any;
  userApiCallInProgress: boolean;

  constructor(private http: HttpClient,
    private modalService: BsModalService,
    private env: EnvService,
    private bsModalRef: BsModalRef,
    private changeOwner: changeOnwerService,
    private orgService: OrganisationService,
    private toastr: ToastrService,
    private translate: TranslateService
    
  ) { }

  ngOnInit(): void {
    this.currentOrgId = this.changeOwner.currentOrgId;
    this.selectedOwner = localStorage.getItem(constants.ORG_OWNER_USER_ID);
    this.getAppUserList();
  }
  onOwnerSelect(selectedValue: string): void {
    this.selectedUserId = selectedValue;
  }

  submit() {
    this.changeOwner.setSelectedUser(this.selectedUserId);
    this.close();
    this.event.emit();
  }
  async getAppUserList() {
    this.userApiCallInProgress = true;
    try {
      const resp = await this.orgService.getUserListForFilter('admin', this.currentOrgId).toPromise();
      this.portalUsers = resp['Data']['Users'];
      this.portalUsers = this.orgService.getUserNames(this.portalUsers);
      console.log("this is portal users " , this.portalUsers)

      this.userApiCallInProgress = false;
      return this.portalUsers;
    } catch (e) {
      this.userApiCallInProgress = false;
      this.toastr.error(this.translate.instant('LABELS.CHANGE_OWNER.UNCAUGHT_GET_USER_LIST_ERROR'));
    }
    this.userApiCallInProgress = false;
    return null;
  }

  close() {
    this.bsModalRef.hide();
  }

}
