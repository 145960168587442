import { Injectable } from '@angular/core';
import { constants } from '../common/constants';
import { EnvService } from '../env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private env: EnvService,
    private httpClient: HttpClient) { }
  public requestedUrl: string = '';
  public activeForm = 'login';
  public buyPackage = null;


  public isAuthenticated(): boolean {

    // TODO: Local storage should be replaced with Angular local storage

    const token = localStorage.getItem(constants.BEARER_TOKEN);
    return token ? true : false;
  }

  public submitDemoRequest(requestObj) {
    const API_URL = `${this.env.apiUrl}/Demo`;
    return this.httpClient.post(API_URL, requestObj).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public isSubscriptionExpired() {
    const currentSubscription = JSON.parse(localStorage.getItem(constants.CURRENT_SUBSCRIPTION));
    if (currentSubscription && currentSubscription.IsExpired === true) {
      return true;
    }
    return false;
  }
}
