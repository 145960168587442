import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants'

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';
import { PortalUser } from '../models/portal-user.model';


@Injectable({
  providedIn: 'root'
})
export class PortalUserService {
  currentUser: any = null;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getPortalUsersList() {
    const API_URL = `${this.env.apiUrl}/PortalUsers`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  createUser(user: PortalUser) {
    const API_URL = `${this.env.apiUrl}/Organisations/${user.OrganisationId}/AddUser`;
    return this.httpClient.post(API_URL, user).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getCountries() {
    const countries = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.COUNTRIES));
    if (countries !== undefined && countries !== null) {
      return of(countries);
    } else {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.COUNTRIES, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  exportStaff() {
    const API_URL = `${this.env.apiUrl}/PortalUsers?Export=true`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  downloadFile(filePath) {
    let a = document.createElement('a')
    a.href = filePath;
    a.download = filePath.split('/').pop()
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deletePetCareStaff(orgId, Id) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/PortalUsers/${Id}`;
    return this.httpClient
      .delete(API_URL, Id)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getStaffById(staffId) {
    const API_URL = `${this.env.apiUrl}/PortalUsers/${staffId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  updatePortalUser(orgId, user, UserID) {
    const API_URL = `${this.env.apiUrl}Organisations/${orgId}/PortalUsers/${UserID}`;
    return this.httpClient.put(API_URL, user).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  doctorProfile(formData, userId) {
    const API_URL = `${this.env.apiUrl}/Users/${userId}/DoctorProfile`;
    return this.httpClient.post(API_URL, formData).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getDoctorProfile(userId) {
    const API_URL = `${this.env.apiUrl}/Users/${userId}/DoctorProfile`
    return this.httpClient.get(API_URL).map((result: any) => {
      return result;
    }). catch(error => {
      return Observable.throw(error);
    });
  }

}
