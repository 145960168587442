
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { ThirdPartyScriptLoader } from './thirdPartyScript-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AclService } from 'ng2-acl/dist';

import { constants } from "../app/common/constants";
import { EnvService } from "./env.service";
import { PaymentService } from "./views/billing/services/payment.service";
import { ServerStatusService } from "./server-status.service";

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [ThirdPartyScriptLoader]
})
export class AppComponent implements OnInit {
  aclData = {};
  incidentIdInit: any;
  orgIdInit: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService,
    private env: EnvService,
    private aclService: AclService,
    private toastr: ToastrService,
    private thirdPartyScriptLoader: ThirdPartyScriptLoader,
    private serverStatus : ServerStatusService
  ) {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    const appLang = userLang ? userLang : this.env.defaultLang;
    translate.setDefaultLang(appLang);
    translate.use(appLang);

    // Analytics tracking
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // HubSpot tracking
        const _hsq = window['_hsq'] = window['_hsq'] || [];
        _hsq.push(['setPath', evt.urlAfterRedirects]);
        _hsq.push(['trackPageView']);
      }
    });
  }

  ngOnInit() {
    this.getServerStatus()

    let isMobile = false;
    const initUrl = window.location.href;
    if(/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      isMobile = true;
    }

    if (initUrl.split("/")[3] === '' || (initUrl.split("/")[4]).includes("home")) {
      console.log("home");
    } else {
      if (isMobile) {
        window.location.href = "vetsoncloud://"
        this.router.navigate(["downloads"]);
      }
    }


    this.thirdPartyScriptLoader.loadHubSpot();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getPricingData();
    this.aclService.setStorageType("localStorage");
    this.env.createOnline().subscribe((isOnline) => {
      if(isOnline == false) {
        this.toastr.error(this.translate.instant('COMMON.NO_INTERNET_CONNECTION_MSG'));
      }
    });
  }

  getServerStatus() {
    this.serverStatus.getServerStatus().subscribe(res => {
      console.log(res.Data.Health);
      if (res.Data.Health === false) {
        this.router.navigate(['maintenance']);
      }
    });
  }

  getPricingData() {
    const userLang =
      localStorage.getItem(constants.APP_LANGUAGE) || this.env.defaultLang;
    this.paymentService.getPricingData(userLang).subscribe(
      (res) => {
        const packages = res["Data"]["Resource"][0]["Packages"];
        packages.forEach((item, index) => {
          const packageName = item.PackageName.replace(/ /g, "_");
          this.aclData[packageName] = [];

          // Dashboard
          const dashboardFeature = item.Features.filter((feature) => {
            return feature.FeatureName === "Dashboard reports";
          });
          if (dashboardFeature.length > 0 && dashboardFeature[0].Enabled) {
            this.aclData[packageName].push("dashboard");
          }

          // Heat Map
          const heatMapFeature = item.Features.filter((feature) => {
            return feature.FeatureName === "Heatmap";
          });
          if (heatMapFeature.length > 0 && heatMapFeature[0].Enabled) {
            this.aclData[packageName].push("heatMap");
          }

          // Live Chat
          const liveChatFeature = item.Features.filter((feature) => {
            return feature.FeatureName === "Chat support";
          });
          if (liveChatFeature.length > 0 && liveChatFeature[0].Enabled) {
            this.aclData[packageName].push("liveChat");
          }

          // GeoFencing
          const geoFencingFeature = item.Features.filter((feature) => {
            return (
              feature.FeatureName === "Geofencing (organisation visibility)"
            );
          });
          if (
            geoFencingFeature.length > 0 &&
            geoFencingFeature[0].FeatureThreshold === "Customizable"
          ) {
            this.aclData[packageName].push("GeoFencingCustomization");
          }
        });
        this.aclService.setAbilities(this.aclData);
        if (localStorage.getItem(constants.CURRENT_SUBSCRIPTION)) {
          const currentPackage = JSON.parse(
            localStorage.getItem(constants.CURRENT_SUBSCRIPTION)
          );
          this.aclService.attachRole(currentPackage.PackageName);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
