import { INavData } from "@coreui/angular";

export const sideNavItems: INavData[] = [
  {
    name: "DASHBOARD_MENU.DASHBOARD",
    url: "/dashboard",
    icon: "fa fa-tachometer"
  },
  {
    name: "DASHBOARD_MENU.PET_ISSUE",
    url: "/issues",
    icon: "fa fa-exclamation-triangle"
  },
  // {
  //   name: "DASHBOARD_MENU.HEATMAP",
  //   url: "/incidents/heatmap",
  //   icon: "fa fa-map-o"
  // },
  {
    name: "DASHBOARD_MENU.APPOINTMENT",
    url: "/appointments",
    icon: "fa fa-calendar"
  },
  {
    name: 'DASHBOARD_MENU.PAYMENTS',
    url: '/payments',
    icon: 'fa fa-money',
  },
  {
    divider: true
  },
  {
    name: "DASHBOARD_MENU.PETS",
    url: "/pets",
    icon: "fa fa-paw"
  },
  {
    name: "DASHBOARD_MENU.PET_PARENTS",
    url: "/users",
    icon: "cui-people"
  },
  {
    name: "DASHBOARD_MENU.PET_ORGANISATIONS",
    url: "/organisations",
    icon: "cui-globe"
  },
  {
    name: "DASHBOARD_MENU.PORTAL_USERS",
    url: "/staff",
    icon: "cui-people"
  },
  {
    name: "DASHBOARD_MENU.SETUP",
    url: "/setup",
    icon: "fa fa-cog"
  }, 
  {
    name: "DASHBOARD_MENU.INVENTORY",
    url: "/inventory",
    icon: "fa fa-cubes"
  },
  {
    divider: true
  },
  // {
  //   name: 'DASHBOARD_MENU.FIELD_STAFF',
  //   url: '/field-staff',
  //   icon: 'fa fa-map-marker'
  // },
  // {
  //   name: 'DASHBOARD_MENU.INTEGRATIONS',
  //   url: '/integrations',
  //   icon: 'fa fa-external-link'
  // },
  {
    name: "DASHBOARD_MENU.NOTIFICATIONS",
    url: "/notifications",
    icon: "fa fa-bell-o"
  }
];
