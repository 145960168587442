import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, Injectable, Injector, NgModule } from "@angular/core";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {
  LocationStrategy,
  HashLocationStrategy,
  LOCATION_INITIALIZED,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from "./app.component";

import { OrganisationService } from "./views/organisations/services/organisation-http.service";

// Import containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { SuccessComponent } from "./views/success/success.component";

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { DataTablesModule } from "angular-datatables";
import { HeaderComponent } from "./containers/header/header.component";

import { AclService } from "ng2-acl";
import { AclRedirection } from "./app.redirection";
import { AclDashboardResolver } from "./views/dashboard/dashboard.resolve";
import { AclBillingResolver } from "./views/billing/billing.resolve";
import { AclHeatMapResolver } from "./views/incidents/incidents-heatmap/incidents.heatmap.resolve";

// RECOMMENDED
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { SendNotificationsComponent } from "./views/notifications/modals/send-notifications/send-notifications.component";
// import { AppointmentsConfigurationComponent } from "./views/appointments/modals/appointments-configuration/appointments-configuration.component";
import { CreateAppointmentComponent } from "./views/appointments/modals/create-appointment/create-appointment.component";
import { EnvServiceProvider } from "./env.service.provider";
import { FormsModule } from "@angular/forms";
import { HttpConfigInterceptor } from "./interceptor/http-config.interceptor";
import { CacheInterceptor } from "../app/interceptor/caching.interceptor";
import { AuthGuardService } from "./auth/auth-gaurd.service";
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { ToastrModule } from "ngx-toastr";
import { TermsAndCondtionsComponent } from "./views/register/modals/terms-and-condtions/terms-and-condtions.component";
import { P401Component } from "./views/error/401.component";
import { CommonModalDialogComponent } from "./components/modal-dialog/common-modal-dialog.component";
import { HomeComponent } from "./views/home/home.component";
import { SharedModule } from "../app/views/shared-module/shared-module.module";
import { CreateEditIntegrationComponent } from "./views/integrations/modals/create-edit-integration/create-edit-integration.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { EnvService } from "./env.service";
import { environment } from "../environments/environment";
import { constants } from "./common/constants";
import { AboutUsComponent } from "./views/about-us/about-us.component";
import { VetCareComponent } from "./views/vet-care/vet-care.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { OurTeamComponent } from "./components/our-team/our-team.component";
import { WhatWeDoComponent } from "./components/about/what-we-do/what-we-do.component";
import { ForNgoComponent } from "./components/about/for-ngo/for-ngo.component";
import { AboutComponent } from "./components/about/about/about.component";
import { FeaturesComponent } from "./components/vet-care/features/features.component";
import { BenefitsComponent } from "./components/vet-care/benefits/benefits.component";
import { VetCareAboutComponent } from "./components/vet-care/vet-care-about/vet-care-about.component";
import { HomeAboutComponent } from "./components/home/home-about/home-about.component";
import { WhatMakesBetterComponent } from "./components/home/what-makes-better/what-makes-better.component";
import { LoginPageComponent } from "./views/login-page/login-page.component";
import { PaymentsConfigurationComponent } from './views/payments/modals/payments-configuration/payments-configuration.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CancelInvoiceComponent } from './views/payments/model/cancel-invoice/cancel-invoice.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ConfirmationModalComponent } from './send-notifications/confirmation-modal/confirmation-modal.component';
import { DownloadsComponent } from './views/downloads/downloads/downloads.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeOwnerComponent } from './views/change-owner/change-owner.component';
import { MaintenanceComponent } from "./maintenance/maintenance.component";


@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private env: EnvService) { }

  getTranslation(lang: string): Observable<any> {
    const apiAddress =
    environment.apiUrl + `/Resources/portal-language-${lang}?v=${new Date().getTime()}`;
    return new Observable((observer) => {
      this.http.get(apiAddress).subscribe(
        (res: any) => {
          if (res.Status === "Success") {
            observer.next(res.Data.Resource);
            observer.complete();
          } else {
            //  failed to retrieve from api, switch to local
            this.http
              .get(`/assets/i18n/portal-language-${lang}.json?v=${new Date().getTime()}`)
              .subscribe((response: Response) => {
                observer.next(response);
                observer.complete();
              });
          }
        },
        (error) => {
          //  failed to retrieve from api, switch to local
          this.http
            .get(`/assets/i18n/portal-language-${lang}.json?v=${new Date().getTime()}`)
            .subscribe((res: Response) => {
              observer.next(res);
              observer.complete();
            });
        }
      );
    });
  }
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const userLang = localStorage.getItem(constants.APP_LANGUAGE);
        const langToSet = userLang ? userLang : "eng";
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(
          () => {
            console.log(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  imports: [
    BrowserModule,
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    ModalModule.forRoot(),
    AutocompleteLibModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    // NgxUiLoaderModule,
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule
      .forRoot({
        excludeRegexp: [
          "Organisations$",
          "Divisions$",
          "Users\\?OrgId",
          "IncidentCountReport",
          "Appointments\\?FromDate",
          "IncidentLocations$",
          "OrganisationStats$",
          "EscalatedIncidents$"
        ],
        showForeground: true,
      }),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ChartsModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    NgbModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    SendNotificationsComponent,
    SuccessComponent,
    TermsAndCondtionsComponent,
    P401Component,
    CommonModalDialogComponent,
    HomeComponent,
    // AppointmentsConfigurationComponent,
    CreateAppointmentComponent,
    CancelInvoiceComponent,
    CreateEditIntegrationComponent,
    AboutUsComponent,
    VetCareComponent,
    NavbarComponent,
    FooterComponent,
    OurTeamComponent,
    WhatWeDoComponent,
    ForNgoComponent,
    AboutComponent,
    FeaturesComponent,
    BenefitsComponent,
    VetCareAboutComponent,
    HomeAboutComponent,
    WhatMakesBetterComponent,
    LoginPageComponent,
    PaymentsConfigurationComponent,

    ConfirmationModalComponent,
    DownloadsComponent,
    ChangeOwnerComponent,
    MaintenanceComponent


  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: AuthGuardService,
      useClass: AuthGuardService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    EnvServiceProvider,
    BsModalRef,
    AclService,
    AclRedirection,
    AclDashboardResolver,
    AclBillingResolver,
    AclHeatMapResolver,
    OrganisationService,
    { provide: LOADING_BAR_CONFIG, useValue: 0 },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TermsAndCondtionsComponent,
    CommonModalDialogComponent,
    SendNotificationsComponent,
    // AppointmentsConfigurationComponent,
    CreateAppointmentComponent,
    CancelInvoiceComponent,
    CreateEditIntegrationComponent,
    PaymentsConfigurationComponent,
    ConfirmationModalComponent,
    ChangeOwnerComponent
  ],
})
export class AppModule { }
