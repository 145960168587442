import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-web-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  year: number = moment().get("year");
  constructor() {}

  ngOnInit(): void {}
}
