import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SetupService {

  currentDivision: any = null;
  currentIntegration: any = null;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getDivisionList() {
    const incidentDivisions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.DIVISIONS));
    if (incidentDivisions !== undefined && incidentDivisions !== null) {
      return of(incidentDivisions);
    } else {
    const API_URL = `${this.env.apiUrl}/Divisions`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  createDivision(division, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions`;
    return this.httpClient.post(API_URL, division).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateDivision(division, orgId, divisionId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${divisionId}`;
    return this.httpClient.put(API_URL, division).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getOrganisationsList() {
    const organisation = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
    if (organisation !== undefined && organisation !== null) {
      return of(organisation);
    } else {
    const API_URL = `${this.env.apiUrl}/Organisations`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.ORGANISATIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  exportCategories() {
    const API_URL = `${this.env.apiUrl}/Divisions?Export=true`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  downloadFile(filePath) {
    let a = document.createElement('a')
    a.href = filePath;
    a.download = filePath.split('/').pop()
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteSetUp(orgId, id) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${id}`;
    return this.httpClient
      .delete(API_URL, id)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  }
