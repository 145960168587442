import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./services/login-http.service";
import { User } from "./models/user.model";
import { constants } from "../../common/constants";
import { TranslateService } from "@ngx-translate/core";
import { SPINNER } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/auth.service";
import { http_error_codes } from "../../common/http_error_codes";
import { AclService } from "ng2-acl/dist";
import * as moment from "moment";
@Component({
  selector: "app-login-component",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit {
  public user: User;
  public spinners = SPINNER;
  public validationInProgress: boolean = false;
  activeVisibleForm: any = "login";
  @Output() buyPackage = new EventEmitter();
  organisationList: any;
  divisionList: any;
  countryList: any;
  genderList: any;
  petTypeList: any;
  locationList: any;
  organisationId: any;
  locations: any;
  lookUpData: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    private aclService: AclService
  ) { }

  ngOnInit() {
    // debugger;
    this.user = new User();
    if (localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS)) {
      this.auth.activeForm = "billingSummaryComponent";
      return;
    }
    if (localStorage.getItem(constants.BEARER_TOKEN)) {
      if (this.aclService.can("dashboard")) {
        this.router.navigate(["dashboard"]);
      } else {
        this.router.navigate(["issues/list"]);
      }
    }
  }

  userLogin() {
    this.validationInProgress = true;
    this.loginService.usreLogin(this.user).subscribe(
      (res) => {
        if (res["Status"] === "Fail") {
          if (res["ErrorCode"] === "INVALID_CREDENTIALS") {
            this.toastr.error(
              this.translate.instant("TOASTR.LOGIN.INVALID_CREDENTIALS")
            );
            return;
          }
          this.toastr.error(
            this.translate.instant("TOASTR.LOGIN.UNCAUGHT_LOGIN_ERROR")
          );
          return;
        }
        const data = res['Data'].Login;
       this.organisationId = data.OrganisationId;
        localStorage.setItem(
          constants.BEARER_TOKEN,
          res["Data"]["Login"]["Token"]
        );
        localStorage.setItem(
          constants.LOGGED_IN_USER_ID,
          res["Data"]["Login"]["UserId"]
        );
        localStorage.setItem(
          constants.IS_ORGANISATION_OWNER,
          res["Data"]["Login"]["IsOwner"]
        );
        localStorage.setItem(
          constants.CLINIC_ID,
          res["Data"]["Login"]["OrganisationId"]
        );
        localStorage.setItem(
          constants.CURRENT_USER_DETAILS,
          JSON.stringify({
            DisplayName: res["Data"]["Login"]["DisplayName"],
            Email: res["Data"]["Login"]["Email"],
            OrganisationId: res["Data"]["Login"]["OrganisationId"],
          })
        );
        const packageName = res["Data"]["Package"]["PackageName"].replace(
          / /g,
          "_"
        );
        localStorage.setItem(
          constants.CURRENT_SUBSCRIPTION,
          JSON.stringify({
            PackageId: res["Data"]["Package"]["Id"],
            PackageName: packageName,
            IsExpired: res["Data"]["Package"]["IsExpired"],
          })
        );
        localStorage.setItem(
          constants.HIGHEST_SUBSCRIPTION,
          JSON.stringify({
            PackageId: res["Data"]["Package"]["HighestPackageId"],
          })
        );
        if (this.aclService.getRoles().length > 0) {
          this.aclService.detachRole(this.aclService.getRoles()[0]);
        }
        this.aclService.attachRole(packageName);
        if (this.auth.requestedUrl) {
          this.router.navigateByUrl(this.auth.requestedUrl);
          this.auth.requestedUrl = "";
        } else {
          this.getCountries();
          this.getTypesOfPets();
          this.getGenderOptions();
          this.getOrganisationsList();
          this.getDivisionList();
          this.getIncidentLocations(this.organisationId);
          this.getPetIssueDetails(this.organisationId);
          this.getLookup();
          if (this.aclService.can("dashboard")) {
            this.router.navigate(["dashboard"]);
          } else {
            this.router.navigate(["issues/list"]);
          }
        }
      },
      (err) => {
        if (err.status !== 403) {
          this.validationInProgress = false;
          if (err.status === http_error_codes.API_CALL_LIMIT_EXCEEDED) {
            this.toastr.error(
              this.translate.instant("COMMON.API_CALL_LIMIT_EXCEEDED")
            );
          } else {
            // TODO : Change error message once they are standardised by the backend.
            this.toastr.error(
              this.translate.instant("TOASTR.LOGIN.UNCAUGHT_LOGIN_ERROR")
            );
          }
        }
      }
    );
  }

  catchLoginErrors(res) {
    this.validationInProgress = false;
    // ToDo : Toast the error messages
    this.toastr.error(res.Message);
  }

  goToRegistration() {
    this.router.navigate(["register"]);
  }

  startFreeTrial() {
    this.buyPackage.emit({});
  }

  showForgotPasswordForm() {
    this.activeVisibleForm = "forgot-password";
  }

  showLoginForm() {
    this.activeVisibleForm = "login";
  }

  getPetIssueDetails(orgId) {
    const strFromDate = moment().startOf('month').add(1, 'days').utc().format('YYYY-MM-DD'); //INFO: +1 because dates are starting from
    const strToDate = moment().endOf('month').utc().format('YYYY-MM-DD');
    this.loginService.getIncidentsListPagination(
      1, 1, orgId, strFromDate, strToDate
    ).subscribe(resp => {
      let totalIncidents = resp['Data']['TotalNumberOfRecords'];
      if (totalIncidents > 200) {
        this.toastr.warning(this.translate.instant('TOASTR.LOGIN.PET_ISSUES_EXCEEDED'));
      }
    }, (error) => {
      // let IncidentsList = [];
    });
  }

  forgotPassword() {
    const requestObj = {
      Email: this.user.Email,
    };
    this.loginService.forgotPassword(requestObj).subscribe(
      (res) => {
        if (res["Status"] === "Fail") {
          if(res["ErrorCode"] === "USER_NOT_EXISTS") {
            this.toastr.error(
              this.translate.instant("TOASTR.LOGIN.USER_NOT_EXISTS")
            );
            return;
          }
            this.catchErrors(res);
            return;
        }
        this.toastr.success(
          this.translate.instant("TOASTR.LOGIN.RESET_PASSWORD_SUCCESS")
        );
        this.showLoginForm();
      },
      (err) => {
        if (err.status !== 403) {
          if (err.status === http_error_codes.API_CALL_LIMIT_EXCEEDED) {
            this.toastr.error(
              this.translate.instant("COMMON.API_CALL_LIMIT_EXCEEDED")
            );
          } else {
            // TODO : Change error message once they are standardized by the backend.
            this.toastr.error(
              this.translate.instant("TOASTR.LOGIN.INVALID_CREDENTIALS")
            );
          }
        }
      }
    );
  }

  catchErrors(err) {
    if (err.status !== 403) {
      const key = Object.keys(err.Data)[0];
      this.toastr.error(err["Data"][key]);
    }
  }
  getOrganisationsList() {
    this.loginService.getOrganisationsList().subscribe((res) => {
      if (res["Status"] === "Fail" || res["Status"] === "Error") {
        this.catchLoginErrors(res);
        return;
      }
      this.organisationList = res["Data"]["Organisations"];
      this.organisationId = this.organisationList[0].Id;
    });
  }

  getDivisionList() {
    this.loginService.getDivisionList().subscribe((res) => {
      if (res["Status"] === "Fail" || res["Status"] === "Error") {
        this.catchLoginErrors(res);
        return;
      }
      this.divisionList = res["Data"]["Divisions"];
    })
  }

  getCountries() {
    this.loginService.getCountries().subscribe((res) => {
      if (res["Status"] === "Fail" || res["Status"] === "Error") {
        this.catchLoginErrors(res);
        return;
      }
      this.countryList = res["Data"]["Countries"];
    });
  }

  getTypesOfPets() {
    this.loginService.getTypesOfPets().subscribe((res) => {
      if (res["Status"] === "Fail" || res["Status"] === "Error") {
        this.catchLoginErrors(res);
        return;
      }
      this.petTypeList = res["Data"]["PetTypes"];
    });

  }

getGenderOptions() {
  this.loginService.getGenderOptions().subscribe((res) => {
    if (res["Status"] === "Fail" || res["Status"] === "Error") {
      this.catchLoginErrors(res);
      return;
    }
    this.genderList = res["Data"]["Gender"];
  });
}

getIncidentLocations(orgId) {
  this.loginService.getIncidentLocations(orgId).subscribe((res) => {
    if (res["Status"] === "Fail" || res["Status"] === "Error") {
      this.catchLoginErrors(res);
      return;
    }
    this.locations = res["Data"]["Locations"];
  });
}

getLookup() {
  this.loginService.getLookup().subscribe((res) => {
    if (res["Status"] === "Fail" || res["Status"] === "Error") {
      this.catchLoginErrors(res);
      return;
    }
  this.lookUpData = res['Data']['LookupData'];
  });
}

}
