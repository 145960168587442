import { Component, OnInit, Input } from '@angular/core';
import { constants } from '../../../common/constants' ;
import { AclService } from 'ng2-acl/dist';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  @Input() purchaseData;
  @Input() isLandingPage;
  public organisationDetails;
  constructor(
    private aclService: AclService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem(constants.PAYMENT_IN_PROGRESS_DETAILS);
    localStorage.removeItem(constants.PREFERRED_CURRENCY);
    if (this.isLandingPage === true) {
      localStorage.removeItem(constants.BEARER_TOKEN);
      this.organisationDetails = JSON.parse(localStorage.getItem(constants.REGISTERED_ORG_DETAILS));
    }
  }

  logout() {
    localStorage.removeItem(constants.BEARER_TOKEN);
    localStorage.removeItem(constants.LOGGED_IN_USER_ID);
    localStorage.removeItem(constants.CURRENT_SUBSCRIPTION);
    localStorage.removeItem(constants.CURRENT_USER_DETAILS);
    localStorage.removeItem(constants.REGISTERED_ORG_DETAILS);
    localStorage.removeItem(constants.IS_ORGANISATION_OWNER);
    if (this.aclService.getRoles().length > 0) {
      this.aclService.detachRole(this.aclService.getRoles()[0]);
    }
    this.router.navigate(['login']);
  }

  showLoginForm() {
    this.auth.activeForm = 'login';
  }
}
