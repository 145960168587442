import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants'

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  currentDivision: any = null;
  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  saveAppointmentsConfig(config, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings`;
    return this.httpClient.post(API_URL, config).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateAppointmentsConfig(config, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings`;
    return this.httpClient.put(API_URL, config).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getAppointmentsConfig(orgId, locationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings?LocationId=${locationId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getSlotsByDate(orgId, date, locationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSlots?Date=${date}&LocationId=${locationId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  createAppointment(orgId, appointment) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Appointments`;
    return this.httpClient.post(API_URL, appointment).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getAppointmentList(params, pageNumber, dtCallback, orgId, sites, fromDate, toDate, searchTerm, sortingColumn, sortingOrder) {
    let API_URL = `${this.env.apiUrl}/Appointments?PageNumber=${pageNumber}&PageLimit=${params.length}&SortingColumn=${sortingColumn}&SortingOrder=${sortingOrder}&SearchTerm=${searchTerm}&FromDate=${fromDate}&ToDate=${toDate}&OrgId=${orgId}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient.get(API_URL).map((data: any) => {
      dtCallback({
        recordsTotal: data.Data.TotalNumberOfRecords || 0,
        recordsFiltered: data.Data.TotalNumberOfRecords || 0,
        data: []
      });
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getQueryParams(name, values) {
    let str = "&";
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  getCalenderViewAppointments(orgId, sites, fromDate, toDate) {
    let API_URL = `${this.env.apiUrl}/Organisations/${orgId}/CalendarData?FromDate=${fromDate}&ToDate=${toDate}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    })
  }

  getTodaysAppointments(orgId, fromDate, toDate) {
    const API_URL = `${this.env.apiUrl}/Appointments?FromDate=${fromDate}&ToDate=${toDate}&OrgId=${orgId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateAppointmentStatus(status, orgId, appointmentId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Appointments/${appointmentId}`;
    return this.httpClient.put(API_URL, {
      ApprovalStatus: status
    }).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getOpenIncidents(orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OpenIncidents`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  exportAppointments(locationId, StartDate, EndDate) {
    let API_URL = `${this.env.apiUrl}/Appointments?Export=true`;
    if (Array.isArray(locationId) && locationId.length > 0) {
      const LocationId = locationId.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    if (StartDate && StartDate !== undefined) {
      API_URL += `&FromDate=${StartDate}`;
    }
    if (EndDate && EndDate !== undefined) {
      API_URL += `&ToDate=${EndDate}`;
    }
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  downloadFile(filePath) {
    let a = document.createElement('a')
    a.href = filePath;
    a.download = filePath.split('/').pop()
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getIncidentLocations(orgId) {
    const incidentLocaltions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (incidentLocaltions !== undefined && incidentLocaltions !== null) {
      return of(incidentLocaltions);
    } else {
      const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
      return this.httpClient.get(API_URL).map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(data));
        return data;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
    }
}
}
