import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsHttpService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getIntegrations() {
    const API_URL = `${this.env.apiUrl}/Integrations`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  createIntegration(integration, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Integrations`;
    return this.httpClient.post(API_URL, integration).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateIntegration(integration, orgId, integrationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Integrations/${integrationId}`;
    return this.httpClient.put(API_URL, integration).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
}
