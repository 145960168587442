import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "../../../env.service";

import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

import { Observable, of } from "rxjs";
import { constants } from "../../../common/constants";
import { error } from "console";

@Injectable({
  providedIn: "root",
})
export class IncidentService {
  currentDivision: any = null;
  currentPetIssue: any = null;
  constructor(private httpClient: HttpClient, private env: EnvService) {}

  getIncidentsList() {
    const API_URL = `${this.env.apiUrl}/Incidents`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  /**
   * Called by the datatable whenever a page or page size changes
   * or a column is reoredered.
   * @param {any} params
   * @param {any} dtCallback
   */
  getIncidentsListPagination(
    params: any,
    pageNumber,
    dtCallback: any,
    orgId,
    divisions,
    sites,
    userId,
    fromDate,
    toDate,
    searchTerm,
    sortingColumn,
    sortingOrder
  ) {
    let API_URL = `${this.env.apiUrl}/Incidents?PageNumber=${pageNumber}&PageLimit=${params.length}&OrgId=${orgId}&UserId=${userId}&StartDate=${fromDate}&EndDate=${toDate}&SortingColumn=${sortingColumn}&SortingOrder=${sortingOrder}&SearchTerm=${searchTerm}`;
    if (Array.isArray(divisions) && divisions.length > 0) {
      const divisionIds = divisions.map((x) => x.id);
      API_URL += this.getQueryParams("DivisionId", divisionIds);
    }
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    if (Array.isArray(sites) && sites.length > 0) {
      const MobileNumber = sites.map(x => x.id);
      API_URL += this.getQueryParams('MobileNumber', MobileNumber);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        this.processIncidentsForNav(
          API_URL,
          pageNumber,
          params.length,
          data.Data.Incidents,
          data.Data.TotalNumberOfRecords
        );
        dtCallback({
          recordsTotal: data.Data.TotalNumberOfRecords || 0,
          recordsFiltered: data.Data.TotalNumberOfRecords || 0,
          data: [],
        });
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getIncidentById(incidentId, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getIncidentHistoryById(
    incidentId,
    orgId,
    pageNumber,
    pageLimit,
    similarIncidentHistory
  ) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/IncidentHistory?PageNumber=${pageNumber}&PageLimit=${pageLimit}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  createHistoryItem(formData, incidentId, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/IncidentHistory`;
    return this.httpClient
      .post(API_URL, formData)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getUserDetails(userId) {
    const API_URL = `${this.env.apiUrl}/Users/${userId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  deleteIncident(orgId, Id) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${Id}`;
    return this.httpClient
      .delete(API_URL, Id)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  exportIncidents(incidentId, locationId, user?, StartDate?, EndDate?) {
    let API_URL = `${this.env.apiUrl}/Incidents?Export=true`;
    if (Array.isArray(incidentId) && incidentId.length > 0) {
      const divisionIds = incidentId.map((x) => x.id);
      API_URL += this.getQueryParams("DivisionId", divisionIds);
    }
    if (Array.isArray(locationId) && locationId.length > 0) {
      const LocationId = locationId.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    if (StartDate && StartDate !== undefined) {
      API_URL += `&StartDate=${StartDate}`;
    }
    if (EndDate && EndDate !== undefined) {
      API_URL += `&EndDate=${EndDate}`;
    }
    API_URL += `&UserId=${user}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  downloadFile(filePath) {
    let a = document.createElement("a");
    a.href = filePath;
    a.download = filePath.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getHeatmapData(orgId, divId) {
    const API_URL = `${this.env.apiUrl}/Reports/Heatmap?OrgId=${orgId}&DivId=${divId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getIncidentStatusByDivData(orgId, toDate, fromDate) {
    const API_URL = `${this.env.apiUrl}/Reports/IncidentStatusReport?OrgId=${orgId}&FromDate=${fromDate}&ToDate=${toDate}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getIncidentCountData(orgId, fromDate, toDate) {
    const API_URL = `${this.env.apiUrl}/Reports/IncidentCountReport?OrgId=${orgId}&StartDate=${fromDate}&EndDate=${toDate}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getQueryParams(name, values) {
    let str = "&";
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  getIncidentsForNavigation(isPrev, pageLength) {
    const url = localStorage.getItem(constants.INCIDENTS_API_CURRENT_FILTER);
    const formattedURL = new URL(url);
    const currentPageNumber = formattedURL.searchParams.get("PageNumber");
    const newPageNumber = isPrev
      ? +currentPageNumber - 1
      : +currentPageNumber + 1;
    formattedURL.searchParams.set("PageNumber", newPageNumber.toString());
    return this.httpClient
      .get(formattedURL.toString())
      .map((data: any) => {
        this.processIncidentsForNav(
          formattedURL.toString(),
          newPageNumber,
          pageLength,
          data.Data.Incidents,
          data.Data.TotalNumberOfRecords
        );
        return true;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  processIncidentsForNav(
    apiURL,
    pageNumber,
    pageLength,
    incidents,
    totalRecordCount
  ) {
    localStorage.setItem(constants.INCIDENTS_API_CURRENT_FILTER, apiURL);
    const incidentList = [];
    incidents.forEach((incident) => {
      incidentList.push({
        Id: incident.Id,
        OrganisationId: incident.OrganisationId,
      });
    });
    const incidentsForNav = {
      incidentList: incidentList,
      totalRecordCount: totalRecordCount,
      pageNumber: pageNumber,
      pageLength: pageLength,
      numOfPages:
        totalRecordCount % pageLength === 0
          ? totalRecordCount / pageLength
          : Math.ceil(totalRecordCount / pageLength),
    };
    localStorage.setItem(
      constants.INCIDENT_LIST_FOR_NAV,
      JSON.stringify(incidentsForNav)
    );
  }

  getAppUserList(orgId) {
    const API_URL = `${this.env.apiUrl}/Users?OrgId=${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  removeSimilarIncident(orgId, incidentId, similarIncidentId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/RemoveSimilarIncident/${similarIncidentId}`;
    return this.httpClient
      .put(API_URL, {})
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  updateAssignedTo(request, orgId, id) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/incidents/${id}/AssignedTo`;
    return this.httpClient
      .put(API_URL, request)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getFieldStaffData(orgId) {
    const API_URL = `${this.env.apiUrl}/FieldStaff?OrgID=${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  createInvoice(orgId, appointment) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices`;
    return this.httpClient.post(API_URL, appointment).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }


  getAllUsers() {
    const API_URL = `${this.env.apiUrl}/Users`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getCategory(orgId) {
    const incidentDivisions = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.DIVISIONS));
    if (incidentDivisions !== undefined && incidentDivisions !== null) {
      return of(incidentDivisions);
    } else {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  getPetsById(userId) {
    const API_URL = `${this.env.apiUrl}/Users/${userId}/Pets`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getOrganisationById(OrgId) {
    const API_URL = `${this.env.apiUrl}Organisations/${OrgId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  createPetIssue(incident, OrgId) {
    const API_URL = `${this.env.apiUrl}Organisations/${OrgId}/Incidents`;
    return this.httpClient.post(API_URL, incident).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  addVitalHistory(data, incidentId, OrganisationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${OrganisationId}/Incidents/${incidentId}/Vitals`;
    return this.httpClient
      .put(API_URL, data)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getInvoiceProducts(orgId, locationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Products?LocationId=${locationId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getMedicalTests(orgId){
    const API_URL = `${this.env.apiUrl}/Organisation/${orgId}/MedicalTests`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) =>{
      return Observable.throw(error);
    });
  }

  printExaminationReport(orgId, incidentId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/ExaminationReport`;
    return this.httpClient.post(API_URL, {}).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  printLatestExaminationAndInvoiceDoc(orgId, incidentId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/Examination-Invoice-Word-Report`;
    return this.httpClient.post(API_URL, {}).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getDiscounts(orgId, userId){
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Users/${userId}/Discounts`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getCountries() {
    const countries = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.COUNTRIES));
    if (countries !== undefined && countries !== null) {
      return of(countries);
    } else {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.COUNTRIES, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

  getTypesOfPets() {
    const petType = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.PET_TYPES));
    if (petType !== undefined && petType !== null) {
      return of(petType);
    } else {
    const API_URL = `${this.env.apiUrl}/GetPetTypes`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.PET_TYPES, JSON.stringify(data));
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
    }
  }

  getAllInvoices(orgId, incidentId){
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/OrgInvoices`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  // This feature is now in Backlog so it will include in future sprint
  // uploadIncidentMedia(mediaData, incidentId) {
  //   const API_URL = `${this.env.apiUrl}/Incidents/${incidentId}/UploadMedia`;
  //   return this.httpClient.post(API_URL, mediaData).map((data: any) => {
  //     return data;
  //   }).catch((error: any) => {
  //     return Observable.throw(error);
  //   });
  // }

  getPetDetailsById(petId) {
    const API_URL = `${this.env.apiUrl}/Pets/${petId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

}
