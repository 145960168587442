import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { SPINNER } from "ngx-ui-loader";
// import { PaymentService } from "../billing/services/payment.service";
import { TranslateService } from "@ngx-translate/core";
import { constants } from "../../common/constants";
import { RegisterService } from "../../views/register/services/register-http.service";
import { EnvService } from "../../env.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  user: any = {};
  public spinners = SPINNER;
  public selectedPackagePriceDetails;
  countryList: any = [];
  languageList: any = [];
  timeSlots: any;
  youTubeUrl: any;
  selectedLang: any;
  supportedLangs: any;
  refreshContent: any = false;
  year: number = moment().get("year");

  constructor(
    public auth: AuthService,
    private toastr: ToastrService,
    // private paymentService: PaymentService,
    private translate: TranslateService,
    private registerService: RegisterService,
    private env: EnvService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.youTubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.env.YouTubeUrl
    );
    this.timeSlots = this.env.demoSlots;
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    this.selectedLang = userLang ? userLang : this.env.defaultLang;
    window["hideLoader"]();
    // this.initLiveChat();
    // this.getCountryList();
    this.getLanguageList();
    this.user.Country = "";
    this.user.Slot = "";
  }

  catchLoginErrors(res) {
    if (res.status !== 403) {
        this.toastr.error(res.Message);
    }
  }

  getLanguageList() {
    this.registerService.getLanguageList().subscribe(
      (res) => {
        if (res["Status"] === "Fail" || res["Status"] === "Error") {
          this.catchLoginErrors(res);
          return;
        }
        this.languageList = res["Data"]["Languages"];
      },
      (err) => {}
    );
  }

  onLangChange() {
    this.refreshContent = true;
    this.translate.setDefaultLang(this.selectedLang);
    this.translate.use(this.selectedLang).subscribe(() => {
      localStorage.setItem(constants.APP_LANGUAGE, this.selectedLang);
      setTimeout(() => {
        this.refreshContent = false;
      }, 1000);
    });
  }
}
