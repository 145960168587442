import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-common-modal-dialog',
  templateUrl: './common-modal-dialog.component.html',
  styleUrls: ['./common-modal-dialog.component.scss']
})
export class CommonModalDialogComponent implements OnInit {

  constructor(private _bsModalRef: BsModalRef) { }
  public onClose: Subject<boolean>;

  icon: string;
  title: string;
  subText: string;
  yesText: string;
  noText: string;

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
