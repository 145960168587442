import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  currentDivision: any = null;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService

  ) { }

  updatePaymentsConfig(config, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdatePaymentDetails`;
    return this.httpClient.put(API_URL, config).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getInvoiceList(orgId, fromDate, toDate, sites) {
    let API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices?FromDate=${fromDate}&ToDate=${toDate}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getQueryParams(name, values) {
    let str = "&";
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  getAssociatedAppointments(orgId) {
    const API_URL = `${this.env.apiUrl}/Appointments`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  createInvoice(orgId, appointment) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices`;
    return this.httpClient.post(API_URL, appointment).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getAllUsers() {
    const API_URL = `${this.env.apiUrl}/Users`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  recordCashPayment(orgId, orgInvoiceId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices/${orgInvoiceId}/CashPayment`;
    return this.httpClient.put(API_URL, {}).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getInvoiceProducts(orgId, branchId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Products?LocationId=${branchId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  downloadFile(filePath) {
    let a = document.createElement("a");
    a.href = filePath;
    a.download = filePath.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  exportIncidents(id, selectedBranch, fromDate, toDate) {
    let API_URL = `${this.env.apiUrl}/Organisations/${id}/OrgInvoices?Export=true&FromDate=${fromDate}&ToDate=${toDate}`;
    if (Array.isArray(selectedBranch) && selectedBranch.length > 0) {
      const LocationId = selectedBranch.map(x => x.id);
      API_URL += this.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getInvoiceById(invoiceId, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices/${invoiceId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  updateInvoice(invoiceId, orgId, invoiceData) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices/${invoiceId}`;
    return this.httpClient.put(API_URL, invoiceData).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getDiscounts(orgId, userId){
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Users/${userId}/Discounts`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  cancelInvoice(orgId, id, reason) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OrgInvoices/${id}`;
    return this.httpClient.put(API_URL, reason).map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }
}
