import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PricingGridComponent } from '../billing/pricing-grid/pricing-grid.component';
import { BillingSummaryComponent } from '../billing-summary/billing-summary.component';
import { PaymentSuccessComponent } from '../billing/payment-success/payment-success.component';
import { PaymentFailureComponent } from '../billing/payment-failure/payment-failure.component';
import { NgxPayPalModule } from 'ngx-paypal';



@NgModule({
  declarations: [
    PricingGridComponent,
    BillingSummaryComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    TranslateModule,
    FormsModule,
    NgxPayPalModule
  ],
  exports: [
    DataTablesModule,
    TranslateModule,
    FormsModule,
    CommonModule,
    PricingGridComponent,
    BillingSummaryComponent,
    PaymentFailureComponent,
    PaymentSuccessComponent
  ]
})
export class SharedModule { }
