import { Injectable } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  public paypalClientId = '';

  public supportEmail = '';

  public heatmapIntensityPercent = 10;

  public YouTubeUrl = '';

  public demoSlots = [];

  public defaultLang = '';

  public version = '';

  public razorPayKey = '';

  constructor() { }

  openAnotherPage(event) {
    const cntrlClick = event.ctrlKey;
    const url = event.target.href;
    if (cntrlClick === false) {
      const alertMessage =  confirm('You are redirecting to another page, if you have any unsaved changes they will be lost.');
      if (alertMessage === true) {
        window.open(url, '_self');
      } else {
       const currentUrl =  window.location.href;
       window.open(currentUrl, '_self');
      }
     }
   }

   createOnline() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

}
