import { ToastrService } from "ngx-toastr";
import { SummaryService } from "./services/summary.service";
import { Summary } from "./models/summary";
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { PaymentService } from "./../billing/services/payment.service";
import { EnvService } from "../../env.service";
import { constants } from "../../common/constants";
import { WindowRefService } from "./window-ref.service";
@Component({
  selector: "app-billing-summary-component",
  templateUrl: "./billing-summary.component.html",
  styleUrls: ["./billing-summary.component.css"],
})
export class BillingSummaryComponent implements OnInit, OnDestroy {
  @Input() priceDetails;
  @Input() isLandingPage;

  couponObj: any;
  public paypalConfig?: IPayPalConfig;
  public taxAmount = 0;
  public isCouponApplied: boolean = false;
  public couponCode: any;
  public couponDiscount;
  public couponDiscountPercent;
  public maxDiscount;
  public isPaymentComplete = false;
  public isPaymentSuccess = false;
  public purchaseData = null;
  public currencies = constants.currencyOptions;
  public preferredCurrency = this.currencies[0].value;
  subTotal: any = 0;
  taxPercentage: any = 0;
  paymentId: any;
  priceBasedOnCurrency: any;
  public order_id: any;
  razorpayData: { OrderId: any; PaymentId: any; Signature: any };
  invoice_id: any;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private env: EnvService,
    private summaryService: SummaryService,
    private winRef: WindowRefService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS)) {
      this.priceDetails = JSON.parse(
        localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS)
      );
      this.preferredCurrency = localStorage.getItem(
        constants.PREFERRED_CURRENCY
      );
    }
    if (this.preferredCurrency === "INR") {
      this.priceDetails.Discount = this.priceDetails.DiscountINR.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceINR.toFixed(2);
      this.priceDetails.PricePerMonth =
        this.priceDetails.PricePerMonthINR.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalINR.toFixed(2);
      this.calcTax();
    } else if (this.preferredCurrency === "USD") {
      this.priceDetails.Discount = this.priceDetails.DiscountUSD.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceUSD.toFixed(2);
      this.priceDetails.PricePerMonth =
        this.priceDetails.PricePerMonthUSD.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalUSD.toFixed(2);
      this.calcTax();
    } else {
      this.priceDetails.Discount = this.priceDetails.DiscountGBP.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceGBP.toFixed(2);
      this.priceDetails.PricePerMonth =
        this.priceDetails.PricePerMonthGBP.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalGBP.toFixed(2);
      this.calcTax();
    }
  }

  createRzpayOrder() {
    // call api to create order_id
    this.createOrderId();
  }

  public payWithRazor() {
    const that = this;
    const options: any = {
      key: this.env.razorPayKey,
      image: "./assets/img/brand/incident_reporter_icon.png", // company logo or product image
      // order_id: "", // order_id created by you in backend
      modal: {
        escape: false, // We should prevent closing of the form when esc key is pressed.
      },
      prefill: {
        name: JSON.parse(
          localStorage.getItem(constants.CURRENT_USER_DETAILS)
        ).DisplayName,
        email: JSON.parse(
          localStorage.getItem(constants.CURRENT_USER_DETAILS)
        ).Email
      },
      theme: {
        color: "#294e67",
      },
    };
    options.handler = (response: any, error: any) => {
      if (response.razorpay_payment_id) {
        this.paymentId = response.razorpay_payment_id;
        this.razorpayData = {
          OrderId: response.razorpay_order_id,
          PaymentId: response.razorpay_payment_id,
          Signature: response.razorpay_signature,
        };
        // Todo: Call this funtions for invoice api
        // this.purchaseLicense();

        // call your backend api to verify payment signature & capture transaction
        this.paymentVerification(this.razorpayData);
      }
    };

    options.name = `Package - ${this.priceDetails.PackageName}`;
    options.description = `No of Months - ${this.priceDetails.NoOfMonths}`;
    options.order_id = this.order_id;

    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response: any) {
      that.isPaymentComplete = true;
      that.isPaymentSuccess = false;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    localStorage.removeItem(constants.PAYMENT_IN_PROGRESS_DETAILS);
    localStorage.removeItem(constants.PREFERRED_CURRENCY);
  }

  onCurrencyUpdated() {
    localStorage.setItem(
      constants.PAYMENT_IN_PROGRESS_DETAILS,
      JSON.stringify(this.priceDetails)
    );
    localStorage.setItem(constants.PREFERRED_CURRENCY, this.preferredCurrency);
    this.priceDetails = {};
    window.location.reload();
  }

  getPriceBasedOnCurrency(currency) {
    if (currency === "USD") {
      this.priceBasedOnCurrency = this.priceDetails.TotalUSD;
      return this.convertCurrency(this.priceBasedOnCurrency);
    } else if (currency === "GBP") {
      this.priceBasedOnCurrency = this.priceDetails.TotalGBP;
      return this.convertCurrency(this.priceBasedOnCurrency);
    } else {
      this.priceBasedOnCurrency = this.priceDetails.TotalINR;
      return this.convertCurrency(this.priceBasedOnCurrency);
    }
  }

  calculateDiscount(couponDiscountPercent, maxDiscount) {
    const discountAmount =
      (this.priceDetails.Price * couponDiscountPercent) / 100;
    if (maxDiscount > discountAmount) {
      this.couponDiscount = discountAmount.toFixed(2);
    } else {
      this.couponDiscount = maxDiscount.toFixed(2);
    }
  }

  validateCoupon() {
    this.paymentService.validateCoupon(this.couponCode).subscribe(
      (res) => {
        if (res["Data"]["Coupon"] === undefined) {
          this.toastr.error(
            this.translate.instant(
              "LABELS.BILLING_SUMMARY.MESSAGES.COUPON_CODE_INVALID"
            )
          );
          return;
        }
        const couponData = res["Data"]["Coupon"];
        this.couponDiscountPercent = couponData.DiscountPercentage;
        this.maxDiscount =
          this.preferredCurrency === "INR"
            ? couponData.MaxDiscountINR
            : this.preferredCurrency === "USD"
            ? couponData.MaxDiscountUSD
            : couponData.MaxDiscountGBP;
        this.calculateDiscount(this.couponDiscountPercent, this.maxDiscount);
        this.priceDetails.Total -= this.couponDiscount;
        this.calcTax();
        this.toastr.success(
          this.translate.instant(
            "LABELS.BILLING_SUMMARY.MESSAGES.COUPON_CODE_APPLIED"
          )
        );
        this.isCouponApplied = true;
      },
      (err) => {
        this.isCouponApplied = false;
        this.toastr.error(
          this.translate.instant(
            "LABELS.BILLING_SUMMARY.MESSAGES.COUPON_APPLY_ERROR"
          )
        );
      }
    );
  }

  removeCoupon() {
    this.couponCode = "";
    this.isCouponApplied = false;
    this.priceDetails.Total += parseFloat(this.couponDiscount);
    this.couponDiscount = 0;
    this.couponDiscountPercent = 0;
  }

  createOrderId() {
    this.purchaseData = {
      PackageId: this.priceDetails.PackageId,
      NumberOfMonths: this.priceDetails.NoOfMonths,
      Coupon: this.couponCode,
      UserCurrency: this.preferredCurrency,
    };

    let organisationId = null;
    if (this.isLandingPage === false) {
      organisationId = JSON.parse(
        localStorage.getItem(constants.CURRENT_USER_DETAILS)
      ).OrganisationId;
    } else {
      organisationId = JSON.parse(
        localStorage.getItem(constants.REGISTERED_ORG_DETAILS)
      ).organisationId;
    }

    this.paymentService
      .createOrderId(this.purchaseData, organisationId)
      .subscribe((res) => {
        if (res["Status"] === "Success") {
          // console.log(res);
          this.order_id = res.Data.Invoice.OrderId;
          this.invoice_id = res.Data.Invoice.Id;
          this.payWithRazor();
        }
      });
  }

  paymentVerification(reqData) {
    const that = this;
    this.purchaseData = {
      AmountPaid: Number(this.priceDetails.Total).toFixed(2),
      Coupon: this.couponCode,
      PackageId: this.priceDetails.PackageId,
      NumberOfMonths: this.priceDetails.NoOfMonths,
      PaymentReferenceNumber: this.paymentId,
      PackageName: this.priceDetails.PackageName,
      CurrencyCode: this.preferredCurrency,
    };
    // console.log(reqData);
    let organisationId = null;
    if (this.isLandingPage === false) {
      organisationId = JSON.parse(
        localStorage.getItem(constants.CURRENT_USER_DETAILS)
      ).OrganisationId;
    } else {
      organisationId = JSON.parse(
        localStorage.getItem(constants.REGISTERED_ORG_DETAILS)
      ).organisationId;
    }

    this.paymentService
      .paymentVerification(reqData, this.invoice_id, organisationId)
      .subscribe(
        (res) => {
          that.isPaymentComplete = true;
          if (res["Status"] === "Success") {
            that.isPaymentSuccess = true;
            console.log("isPaymentSuccess", this.isPaymentSuccess);
            // Todo: Call this funtions for invoice api
            // this.purchaseLicense();
          } else {
            that.isPaymentSuccess = false;
            console.log("isPaymentSuccess", this.isPaymentSuccess);
          }
          this.ref.detectChanges();
        },
        (err) => {
          that.isPaymentComplete = true;
          that.isPaymentSuccess = false;
          this.ref.detectChanges();
        }
      );
  }

  calcTax() {
    this.taxPercentage = constants.taxPercentages[this.preferredCurrency];
    this.taxAmount = this.priceDetails.Total * (this.taxPercentage / 100);
    this.subTotal = this.priceDetails.Price - this.taxAmount;
  }

  convertCurrency(amount: number) {
    return amount.toString().split(".").join("");
  }
}
