const SortUserNamesAlphabetically = (rawData) => {
  console.log('helper being useed');
  const sortedUserList = rawData.sort(function(a, b) {
    var firstName = a.DisplayName.toUpperCase();
    var secondName = b.DisplayName.toUpperCase();
    if(firstName && secondName){
      if(firstName < secondName) { return -1; };
      if(firstName > secondName) { return 1; };
      return 0;
    }
    if(!firstName && secondName){
      return 1;
    }
    if(firstName && !secondName){
      return -1;
    }
    return 0;
  });
  return sortedUserList;
}

export default SortUserNamesAlphabetically;