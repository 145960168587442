import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { AuthGuardService as AuthGaurd } from "./auth/auth-gaurd.service";
import { SuccessComponent } from "./views/success/success.component";
import { P401Component } from "./views/error/401.component";
import { HomeComponent } from "./views/home/home.component";
import { AboutUsComponent } from "./views/about-us/about-us.component";
import { VetCareComponent } from "./views/vet-care/vet-care.component";
import { LoginPageComponent } from "./views/login-page/login-page.component";
import { DownloadsComponent } from "./views/downloads/downloads/downloads.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "401",
    component: P401Component,
    data: {
      title: "Page 401",
    },
  },
  {
    path : "maintenance",
    component : MaintenanceComponent
  },
  {
    path: "home",
    component: HomeComponent,
    data: {
      title: "Home page",
    },
  },
   {
    path: "#demo",
    component: HomeComponent,
    data: {
      title: "Home page",
    },
  },
  {
    path: "#pricing",
    component: HomeComponent,
    data: {
      title: "Home page",
    },
  },
  {
    path: "#benefits",
    component: HomeComponent,
    data: {
      title: "Home page",
    },
  },
  {
    path: "#about",
    component: HomeComponent,
    data: {
      title: "Home page",
    },
  },
  // {
  //   path: "about",
  //   component: AboutUsComponent,
  //   data: {
  //     title: "About page",
  //   },
  // },
  {
    path: "login",
    component: LoginPageComponent,
    data: {
      title: "LogIn",
    },
  },
  {
    path: "success",
    component: SuccessComponent,
    data: {
      title: "Success Page",
    },
  },
  {
    path: 'downloads',
    component: DownloadsComponent,
    data: {
      title: "downloads page"
    }
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "organisations",
        loadChildren: () =>
          import("./views/organisations/organisations.module").then(
            (m) => m.OrganisationsModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "appointments",
        loadChildren: () =>
          import("./views/appointments/appointments.module").then(
            (m) => m.AppointmentsModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./views/users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGaurd],
      },
      {
        path: 'payments',
        loadChildren: () => import('./views/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [AuthGaurd]
      },
      {
        path: "staff",
        loadChildren: () =>
          import("./views/portal-users/portal-users.module").then(
            (m) => m.PortalUsersModule
          ),
        canActivate: [AuthGaurd],
      },
      // {
      //   path: 'field-staff',
      //   loadChildren: () => import('./views/field-staff/field-staff/field-staff.module').then(m => m.FieldStaffModule),
      //   canActivate: [AuthGaurd]
      // },
      {
        path: "issues",
        loadChildren: () =>
          import("./views/incidents/incidents.module").then(
            (m) => m.IncidentsModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "pets",
        loadChildren: () =>
          import("./views/pets/pets.module").then(
            (m) => m.IncidentsModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "setup",
        loadChildren: () =>
          import("./views/setup/setup.module").then((m) => m.SetupModule),
        canActivate: [AuthGaurd],
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./views/inventory/inventory.module").then((m) => m.InventoryModule),
        canActivate: [AuthGaurd],
      },
      // {
      //   path: 'integrations',
      //   loadChildren: () => import('./views/integrations/integrations.module').then(m => m.IntegrationsModule),
      //   canActivate: [AuthGaurd]
      // },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
        canActivate: [AuthGaurd],
      },
      {
        path: "billing",
        loadChildren: () =>
          import("./views/billing/billing.module").then((m) => m.BillingModule),
      },
      {
        path: "changepassword",
        loadChildren: () =>
          import("./views/change-password/change-password.module").then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: "email-support",
        loadChildren: () =>
          import("./views/email-support/email-support.module").then(
            (m) => m.EmailSupportModule
          ),
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
