import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  usreLogin(user) {
    const API_URL = `${this.env.apiUrl}/Login`;
    return this.httpClient.post(API_URL, user).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  forgotPassword(user) {
    const API_URL = `${this.env.apiUrl}/ResetPassword`;
    return this.httpClient.put(API_URL, user).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  clearLogin() {
    localStorage.removeItem(constants.BEARER_TOKEN);
    localStorage.removeItem(constants.LOGGED_IN_USER_ID);
    localStorage.removeItem(constants.CURRENT_SUBSCRIPTION);
    localStorage.removeItem(constants.CURRENT_USER_DETAILS);
    localStorage.removeItem(constants.REGISTERED_ORG_DETAILS);
    localStorage.removeItem(constants.IS_ORGANISATION_OWNER);
    localStorage.removeItem(constants.INCIDENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.HEATMAP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DASHBOARD_FILTER_PREFERENCES);
    localStorage.removeItem(constants.APPOINTMENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.PAYMENT_FILETER_PREFERENCES);
    localStorage.removeItem(constants.USERS_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DATA_TABLE_STATES.INCIDENTS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.USERS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.APPOINTMENTS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.PAYMENTS);
    localStorage.removeItem(constants.INCIDENT_LIST_FOR_NAV);
    localStorage.removeItem(constants.INCIDENTS_API_CURRENT_FILTER);
    localStorage.removeItem(constants.INVENTORY_FILETER_PREFERENCES);
    localStorage.removeItem(constants.CLINIC_ID);
    const storageKeys = [constants.STORAGE_KEYS.ORGANISATIONS, constants.STORAGE_KEYS.COUNTRIES,
      constants.STORAGE_KEYS.PET_TYPES, constants.STORAGE_KEYS.GENDER_TYPES,
      constants.STORAGE_KEYS.DIVISIONS, constants.STORAGE_KEYS.INCIDENT_LOCATIONS,
       constants.STORAGE_KEYS.LOOKUP, constants.DATA_TABLE_STATES.PETS, constants.DATA_TABLE_STATES.INVENTORY,
     constants.HIGHEST_SUBSCRIPTION];
     storageKeys.forEach(k => localStorage.removeItem(k));
  }

  getOrganisationsList() {
      const API_URL = `${this.env.apiUrl}/Organisations`;
      return this.httpClient.get(API_URL).map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.ORGANISATIONS, JSON.stringify(data));
        return data;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getDivisionList() {
    const API_URL = `${this.env.apiUrl}/Divisions`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getIncidentLocations(orgId) {
      const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
      return this.httpClient.get(API_URL).map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(data));
        return data;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getIncidentsListPagination(
    pagelimit,
    pageNumber,
    orgId,
    fromDate,
    toDate,

  ) {
    let API_URL = `${this.env.apiUrl}/Incidents?PageNumber=${pageNumber}&PageLimit=${pagelimit}&OrgId=${orgId}&StartDate=${fromDate}&EndDate=${toDate}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getCountries() {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.COUNTRIES, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  getTypesOfPets() {
    const API_URL = `${this.env.apiUrl}/GetPetTypes`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.PET_TYPES, JSON.stringify(data));
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getGenderOptions(): Observable<any> {
    const API_URL = `${this.env.apiUrl}/GetGenderTypes`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.GENDER_TYPES, JSON.stringify(data));
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getLookup() {
    const API_URL = `${this.env.apiUrl}/lookup`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        localStorage.setItem(constants.STORAGE_KEYS.LOOKUP, JSON.stringify(data));
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
}

isUserLoggedIn(){
  if(localStorage.getItem(constants.CURRENT_USER_DETAILS)){
    return true;
  }else{
    return false;
  }
}

}
