import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register-http.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../../../common/constants';
import { EnvService } from '../../../../env.service';
@Component({
  selector: 'app-terms-and-condtions',
  templateUrl: './terms-and-condtions.component.html',
  styleUrls: ['./terms-and-condtions.component.css']
})
export class TermsAndCondtionsComponent implements OnInit {
  public termsAndConditions;
  constructor(
    private registerService: RegisterService,
    private modalRef: BsModalRef,
    private translate: TranslateService,
    private env: EnvService
  ) {
  }

  ngOnInit(): void {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE) || this.env.defaultLang;
    this.registerService.getTermsConditions(userLang).subscribe(res => {
      this.termsAndConditions = res;
    }, err => {
      // TODO : Toast the error message
    });
  }

  closeTermsAndConditions() {
    this.modalRef.hide();
  }
}
