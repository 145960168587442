import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { constants } from '../common/constants';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isAuthenticated() === false) {
      this.router.navigate(['login']);
      this.auth.requestedUrl = state.url;
      return false;
    }
    if (this.auth.isSubscriptionExpired() === true) {
      this.router.navigate(['billing/list']);
      return false;
    }
    return true;
  }
}
