import { AuthService } from './../../../auth/auth.service';
import { Component, OnInit, Input, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { PaymentService } from './../services/payment.service';
import { Package } from '../models/package.model';
import { Feature } from '../models/feature.model';
import { constants } from '../../../common/constants';
import { Router } from '@angular/router';
import { EnvService } from '../../../env.service';

@Component({
  selector: 'app-pricing-grid',
  templateUrl: './pricing-grid.component.html',
  styleUrls: ['./pricing-grid.component.scss']
})
export class PricingGridComponent implements OnInit {
  @Input() isLandingPage;
  @Output() buyPackage = new EventEmitter();

  public packages: Array<Package>;
  public features: Array<Feature>;
  public pricingData: any;
  public months: any = constants.packageMonths;
  public currentPackage;
  freeTrialPackage: any = {};
  public couponData: any = [];
  selectedCurrency: any = 'INR';

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private env: EnvService
  ) { }

  ngOnInit(): void {
    this.currentPackage = JSON.parse(localStorage.getItem(constants.HIGHEST_SUBSCRIPTION));
    this.getPricingData();
  }

  getPricingData() {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE) || this.env.defaultLang;
    this.paymentService.getPricingData(userLang).subscribe((res) => {
      this.packages = res['Data']['Resource'][0]['Packages'];
      this.couponData = res['Data']['Resource'][0]['Coupons'] || [];
      this.freeTrialPackage = this.packages[0];
      // Remove free trial account.
      if (this.freeTrialPackage.PackageName.includes('Free Trial')) {
        this.packages.splice(0, 1);
      }
      this.features = this.packages.length > 0 ? this.packages[0].Features : [];
      this.features = this.features.map((f) => {
        return Object.assign(new Feature(), f);
      });
      this.pricingData = this.getPricingTableData(this.features, this.packages);
    }, (err) => {
      console.log(err);
    });
  }

  getFeatureQuantity(pkg: Package, feature: Feature) {
    const pkgFeature = pkg.Features.filter(f => {
      return f.Id === feature.Id;
    });

    const selectedFeature = pkgFeature.length > 0 ? pkgFeature[0] : null;
    return selectedFeature.FeatureThreshold;
  }

  getPricingTableData(features: Array<Feature>, packages: Array<Package>) {
    const packageNames = this.getPackageNames(packages);
    const data = [];
    features.forEach(feature => {
      const row = {
        featureName: feature.FeatureName,
      };
      packages.forEach(pkg => {
        const pkgFeature = pkg.Features.filter(f => f.Id === feature.Id);
        if (pkgFeature.length > 0) {
          row[pkg.PackageName] = pkgFeature[0].FeatureThreshold !== undefined ? pkgFeature[0].FeatureThreshold : pkgFeature[0].Enabled;
        }
      });

      data.push(row);
    });

    return data;
  }

  getPackageNames(packages: Array<Package>) {
    const packageNames = packages.map(p => p.PackageName);
    return packageNames;
  }

  onBuyClick(event, pkg) {
    if (pkg.Id !== 1) {
      const selectedNumMonths = constants.packageMonths[event.path[1].firstElementChild.selectedIndex].value;
      pkg.NumOfMonths = selectedNumMonths;
    }
    this.buyPackage.emit(pkg);
  }

  startFreeTrial() {
    this.onBuyClick(null, this.freeTrialPackage);
  }
}
