import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../../../env.service";
import { Observable } from "rxjs";

import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private env: EnvService, private httpClient: HttpClient) {}

  getPricingData(lang: any) {
    const API_URL = `${this.env.apiUrl}/Resources/package-features-${lang}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  getPriceDetails(packageId, numMonths) {
    const API_URL = `${this.env.apiUrl}/Packages/${packageId}/PriceDetails?NoOfMonths=${numMonths}`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  validateCoupon(couponCode) {
    const API_URL = `${this.env.apiUrl}/Coupons`;
    return this.httpClient
      .post(API_URL, { CouponCode: couponCode })
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  purchaseLicense(reqData, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Invoices`;
    return this.httpClient
      .post(API_URL, reqData)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  createOrderId(reqData, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/CreateOrder`;
    return this.httpClient
      .post(API_URL, reqData)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  paymentVerification(reqData, invoice_id, organisationId) {
    // const API_URL = `${this.env.apiUrl}/Payment/Verify`;
    const API_URL = `${this.env.apiUrl}/Organisations/${organisationId}/Invoices/${invoice_id}/Verify`;
    return this.httpClient
      .post(API_URL, reqData)
      .map((data: any) => {
        return data;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }
}
