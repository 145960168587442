import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AclService } from 'ng2-acl';
import { AclRedirection } from '../../app.redirection';

@Injectable()
export class AclDashboardResolver implements Resolve<any> {
  constructor(
    private aclService: AclService, private router: Router, private aclRedirection: AclRedirection,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.aclService.can('dashboard')) {
      // Has proper permissions
      return of(true);
    } else {
      // Does not have permission
      this.aclRedirection.redirectTo('Unauthorized');
    }
  }
}
