import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
   providedIn: 'root'
 })

export class confirmationService {
   private sendNotificationSource = new Subject<void>();
   sendNotification$ = this.sendNotificationSource.asObservable();
   close$ = this.sendNotificationSource.asObservable();
   public formData: any = {};
   confirmationData : any;

   }

