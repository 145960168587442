import { Component, OnInit, Input, EventEmitter} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaymentsService } from '../../payments.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-invoice',
  templateUrl: './cancel-invoice.component.html',
  styleUrls: ['./cancel-invoice.component.css']
})
export class CancelInvoiceComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  orgId: any;
  Reason: any;
  invoiceData: Object;
  invoiceId: any;
  invoiceNumber: any;
  fromDate: any;
  toDate: any;
  invoiceList: any;
  branch: any;
  cancelReason: any;


  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private paymentService: PaymentsService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.invoiceData = this.modalService.config.initialState;
    this.invoiceNumber = this.invoiceData['InvoiceNumber'];
    this.invoiceId = this.invoiceData['Id'];
    this.orgId = this.invoiceData['OrganisationId'];
  }

  close() {
    this.bsModalRef.hide();
  }

  cancelInvoice() {
    const cancelReason = {
      Reason: this.cancelReason,
      InvoiceStatus: "Cancelled"
    };

    this.paymentService.cancelInvoice(this.orgId, this.invoiceId, cancelReason).subscribe((res) => {
      if (res['Status'] === 'Success') {
        this.toastr.success(this.translate.instant('TOASTR.INVOICE.CANCELLED_INVOICE_SUCCESS'));
      }
    });
    this.close();
    this.event.emit();
  }

}
