import { Component } from '@angular/core';
import { LoginService } from '../login/services/login-http.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  constructor(private loginService:LoginService,private router:Router) { }

  OnClick(){
    let isUserLoggedIn=this.loginService.isUserLoggedIn();
    if(isUserLoggedIn){
      this.router.navigate(['dashboard']);
    }else{
      this.router.navigate(['']);
    }
  }
}
