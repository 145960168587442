import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from '../../../setup/services/setup-http.service';
import { AppointmentsService } from '../../services/appointments-http.service';
import * as moment from 'moment';
import { PortalUserService } from '../../../portal-users/services/portal-users-http.service';
import { IncidentService } from '../../../incidents/services/incident-http.service';
import { Router } from '@angular/router';
import { constants } from '../../../../common/constants';
import SortUserNamesAlphabetically from '../../../../helpers/sortNamesAlphabetically';

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.css']
})
export class CreateAppointmentComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  appointment: any = {};
  organisationsList: any;
  slots: any;
  minDate: any = new Date();
  countryCodes: any;
  public phoneNumberPattern = '^[0-9]{7,}$';
  orgWorkingDays: any = null;
  openIncidents: any;
  today = new Date();
  todayTime: any;
  todayDate: any;
  petList: any;
  userId: any;
  usersList: any;
  clinicsList: any;
  incidents: any;
  noAppointmentsDay: boolean = false;
  disableSubmit: boolean = false;
  appointmentSettings: any = [];
  notAppointments: boolean = false;
  divisionList: any;
  latitude: any;
  longitude: any;
  Organizations: any;
  OrganisationName: any;
  usersLists: any;
  isNewUser: boolean;
  searchedString = '';
  noParentElementFound: string;
  keyword = 'DisplayName';
  placeholder = 'Select pet parent or Add';
  noPetElementFound: string;
  searchedPetString = '';
  isNewPet: boolean;
  petTypes: any;
  searchKeyword = 'PetName';
  petPlaceholder = 'Select pet or Add';
  appointmentsList: any;
  selectedBranch: any;
  selectedToDate: Date;
  selectedFromDate: Date;

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private setupService: SetupService,
    private appointmentsService: AppointmentsService,
    private portalUserService: PortalUserService,
    private incidentService: IncidentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadOrganisationsList();
     this.todayDate = moment(this.appointment.date).format('YYYY-MM-DD');
     this.todayTime = moment.utc(this.today, 'HH:mm:ss').local().format('HH:mm');
     this.getAllUsers();
     this.getCategoryList();
     this.getCountryCodes();
     this.getTypesOfPets();
  }

  loadOrganisationsList() {
    this.setupService.getOrganisationsList().subscribe((res) => {
      this.organisationsList = res['Data']['Organisations'];
      if (this.organisationsList !== null && this.organisationsList.length > 0) {
        this.appointment.OrganisationId = this.organisationsList[0].Id.toString();
        this.OrganisationName = this.organisationsList[0].OrganisationName;
        this.getOrganisationById();
        this.appointment.IncidentId = null;
        this.getOpenIncidentsOfOrganization(this.appointment.OrganisationId);
       this.getIncidentLocations();
      }
    }, (err) => {
      if (err.status !== 403) {
        this.toastr.error(this.translate.instant('TOASTR.ORGANISATIONS.UNCAUGHT_GET_ORG_LIST_ERROR'));
      }
    });
  }

  getOrganisationById() {
    this.incidentService.getOrganisationById(this.appointment.OrganisationId).subscribe((res) => {
      this.Organizations = res['Data']['Organisation'];
      this.latitude = this.Organizations['Center']['Lat'];
      this.longitude = this.Organizations['Center']['Lng'];
    });
  }

  getAppointmentSettings(orgId, LocationId) {
    this.appointmentsService.getAppointmentsConfig(orgId, LocationId).subscribe((res) => {
      const appointmentSettings = res['Data']['AppointmentSettings'];
      this.appointmentSettings = appointmentSettings;
      if (appointmentSettings !== null && appointmentSettings.length > 0) {
        const filteredSettings = appointmentSettings.filter(obj => {
          return obj.OrganisationId === parseInt(orgId, 10);
        });
      }
    }, (err) => {
    });
  }

  loadSlots() {
    let dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let day = dayName[this.appointment.date.getDay()];
    const date = moment(this.appointment.date).format('YYYY-MM-DD');
    this.notAppointments = false;
    if(this.notAppointments) {
      this.slots = [];
      this.disableSubmit = true;
      return;
    } else {
      this.disableSubmit = false;
    }
    this.appointmentsService.getSlotsByDate(this.appointment.OrganisationId, date, this.appointment.LocationId).subscribe((res) => {
      if (res['Status'] === 'Fail' || res['Status'] === 'Error') {
        if(res.Code == 'NO_APPOINTMENT_SLOTS') {
          this.noAppointmentsDay = true;
          this.slots = [];
          this.toastr.warning(this.translate.instant('TOASTR.APPOINTMENTS.NOT_WORKING_DAY').replace('{days}', day));
          return;
        }
        this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.APPOINTMENTS_NOT_ALLOWED'));
        return;
      }
      this.slots = res['Data']['AvailableSlots'];
      let today = new Date();
      const todayDate = moment(today).format('YYYY-MM-DD');
       let formatDate = moment.utc(today, 'HH:mm:ss').local().format('HH:mm:ss');
      if(todayDate == date) {
        this.slots.forEach(element => {
          let formatSlotTime = moment.utc(element.SlotStartTime, 'HH:mm:ss').local().format('HH:mm:ss');
           if(formatDate > formatSlotTime) {
            element.Availability = false;
           }
        })
      }
      this.noAppointmentsDay = false;
    }, (err) => {
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  getFormattedTimeSlot(slot) {
    const startTime = moment
      .utc(slot.SlotStartTime, "HH:mm:ss")
      .local()
      .format("hh:mm A");
    return startTime;
  }

  selectSlot(slot) {
    if (!slot.Availability) {
      return;
    }
    this.slots.forEach((element: any) => {
      element.selected = false;
    });
    slot.selected = true;
  }

  createAppointment() {
    const selectedSlot = this.getSelectedSlot();
    if (selectedSlot === null) {
      this.toastr.info(this.translate.instant('TOASTR.APPOINTMENTS.SELECT_TIME_SLOT'));
      return;
    }

    if (this.appointment.DisplayName === this.searchedString) {
      this.appointment.userId = '';
    }
    if (this.appointment.PetName === this.searchedPetString) {
      this.appointment.petId = '';
    }
    if (this.appointment.UserId !== undefined) {
      if (this.appointment.UserId.Id) {
        this.appointment.UserId = this.appointment.UserId.Id;
      } else {
        this.appointment.UserId = "";
      }
    }
    if (this.appointment.petId !== undefined) {
      if (this.appointment.petId.Id) {
        this.appointment.petId = this.appointment.petId.Id;
      } else {
        this.appointment.petId = "";
      }
    }
    if (this.searchedString !== "") {
      if ((this.appointment.MobileNumber === null || this.appointment.MobileNumber.toString() === '') &&
      (this.appointment.CountryCode !== '' || this.appointment.CountryCode !== null)) {
      this.toastr.info(this.translate.instant('LABELS.Users.VALIDATION.MOBILE_NUMBER_REQUIRED'));
      return;
    }
    if ((this.appointment.MobileNumber !== null || this.appointment.MobileNumber.toString() !== '') &&
      (this.appointment.CountryCode === '' || this.appointment.CountryCode === null)) {
      this.toastr.info(this.translate.instant('LABELS.Users.VALIDATION.COUNTRY_CODE_REQUIRED'));
      return;
    }
    }
    if (this.searchedPetString !== "" && this.searchedPetString !== undefined) {
      if (this.isNewPet && this.appointment.TypeOfPet === undefined)  {
        this.toastr.warning(this.translate.instant('TOASTR.INCIDENTS.TYPE_OF_PET_REQUIRED'));
        return;
      }
    }
    if (localStorage.getItem(constants.APPOINTMENT_FILTER_PREFERENCES)) {
      const AppointmentsPreferences = JSON.parse(localStorage.getItem(constants.APPOINTMENT_FILTER_PREFERENCES));
      this.selectedBranch = AppointmentsPreferences.appointmentSiteFilter;
      this.selectedToDate = new Date(AppointmentsPreferences.toDate),
      this.selectedFromDate = new Date(AppointmentsPreferences.fromDate);
    }
    const appointment = {
      UserId: this.appointment.UserId,
      CountryID: this.appointment.CountryID,
      MobileNumber: this.appointment.MobileNumber,
      AppointmentStartTime: moment(this.appointment.date).format('YYYY-MM-DD') + 'T' + selectedSlot.SlotStartTime + 'Z',
      AppointmentEndTime: moment(this.appointment.date).format('YYYY-MM-DD') + 'T' + selectedSlot.SlotEndTime + 'Z',
      Purpose: this.appointment.Purpose,
      IncidentId: this.appointment.IncidentId ? parseInt(this.appointment.IncidentId, 10) : '',
      PetId: this.appointment.petId,
      LocationId: this.appointment.LocationId,
      LocationName: this.OrganisationName,
      CategoryId: this.appointment.divisionId,
      Latitude: this.latitude,
      Longitude: this.longitude,
      DisplayName: this.appointment.DisplayName,
      PetName: this.appointment.PetName,
      CountryCode: this.appointment.CountryCode,
      TypeOfPet: this.appointment.TypeOfPet
    };
    this.disableSubmit = true;
    this.appointmentsService.createAppointment(this.appointment.OrganisationId, appointment).subscribe((res) => {
      if (res["Status"] === "Success") {
        this.close();
        this.toastr.success(this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_SUCCESS'));
        this.disableSubmit = false;
        this.router.navigate([`appointments/list`]);
        this.event.emit();
      } else {
        if (res.Data.MobileNumber === 'Mobile Number Already Exist.') {
          this.toastr.warning(this.translate.instant('TOASTR.INCIDENTS.MOBILE_NUMBER_ALREADY_EXIST'));
          return;
        } else if (res.Data.DisplayName === 'DisplayName is required.' ) {
          this.toastr.warning(this.translate.instant('TOASTR.INCIDENTS.PET_PARENT_REQUIRED'));
          return;
        } else if (res['Status'] === 'Fail') {
          this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_FAIL'));
        return;
        }
      }
    }, (err) => {
      this.disableSubmit = false;
      if (err.status !== 403) {
        this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_FAIL'));
      }
    });
  }

  getSelectedSlot() {
    const selectedSlot = this.slots.filter((slot: any) => {
      return slot.selected === true;
    });
    if (selectedSlot.length > 0) {
      return selectedSlot[0];
    }
    return null;
  }

  getOpenIncidentsOfOrganization(id) {
    this.appointmentsService.getOpenIncidents(id).subscribe((res) => {
      this.incidents = res['Data']['Incidents'];
    }, (err) => {
    });
  }

  onSlectUser($event) {
    this.openIncidents = [];
    this.userId = $event.Id;
    this.usersList.filter(item => {
      if (parseInt(this.userId) === item.Id) {
        this.appointment.CountryID = item.CountryId;
        this.appointment.MobileNumber = item.MobileNumber;
      }
    });
    this.getPetsByID();
    this.openIncidents = this.incidents.filter(item => {
      if (parseInt(this.userId) === item.UserId) {
        return item;
      }
    });
    if (this.openIncidents.length === 0) {
      this.appointment.IncidentId = null;
    }
  }

  getPetsByID() {
    this.incidentService.getPetsById(this.userId).subscribe(res => {
      this.petList = res['Data']['Pets'];
    });

  }

  getAllUsers() {
    this.incidentService.getAllUsers().subscribe((res) => {
      const response = res['Data']['Users'];
      const sortedUserList = SortUserNamesAlphabetically(response);
      this.usersList = sortedUserList.filter((item) => {
        if (item.Status === 'Active') {
          if (item.DisplayName === '' && item.MobileNumber !== '') {
            item.DisplayName = item.CountryCode + ' ' + item.MobileNumber;
          }
          return item;
        }
      })
    }, (err) => {
    });
  }

  getIncidentLocations() {
    this.appointmentsService.getIncidentLocations(this.appointment.OrganisationId).subscribe((res) => {
        this.clinicsList = res['Data']['Locations'];
        if (this.clinicsList.length <= 1) {
          this.appointment.LocationId = this.clinicsList[0].Id;
          this.getAppointmentSettings(this.appointment.OrganisationId,  this.appointment.LocationId);
        }
    }, (err) => {
      // TODO: Collect all possible errors and toast them accordingly
    });
  }

  getCategoryList() {
    this.incidentService.getCategory(this.appointment.OrganisationId).subscribe((res) => {
      this.divisionList = res['Data']['Divisions'];
    });
  }

  changeDate() {
    this.appointment.date = undefined;
    this.slots = [];
  }

  getCountryCodes() {
    let countryPinCode = null;
    this.incidentService.getCountries().subscribe((res) => {
      this.countryCodes = res['Data']['Countries'];
      const countryCode = this.countryCodes.filter(item => {
        if (item.PhoneCode === "+91" ) {
          countryPinCode = item.PhoneCode;
          return countryPinCode;
        }
    });
    this.appointment.CountryCode = countryPinCode;
    }, (err) => {
      // TODO: Collect all posbile errors and toast them accordingly
    });
  }

  addNewParent() {
    this.isNewUser = true;
    this.appointment.DisplayName = this.searchedString;
    this.appointment.MobileNumber = "";
  }

  selectParent(event) {
    this.isNewUser = false;
    this.isNewUser = false;
    this.searchedString = "";
    console.log('selectParentEvent', event);
    if (event.Id) {
      this.onSlectUser(event);
    } else {
      this.petList = [];
    }
   }

   onParentChangeSearch(event) {
     this.searchedString = '';
    console.log('onParentChangeSearch', event);
    this.noParentElementFound = "<i class='fa fa-plus'></i>" +  "&nbsp;" + "Create new pet parent :" + "&nbsp;" + "&nbsp;" + event;
    this.searchedString = event;
    this.isNewUser = false;
    this.isNewUser = false;
   }

   clearPetParentSearch(appointment) {
    this.isNewUser = false;
    appointment.petId = "";
   }

   clearPetSearch() {
     this.isNewPet = false;
   }

   onParentFocused(event) {
    console.log('onParentFocused', event);
   }

   selectPet(event) {
    console.log('select event', event);
    this.isNewPet = false;
    this.searchedPetString = ""
  }

  onPetFocused(event) {
    console.log('onFocused', event);
  }

  onPetChangeSearch(event) {
    console.log('onChangeSearch', event);
    this.noPetElementFound = "<i class='fa fa-plus'></i>" +  "&nbsp;" + "Create new pet :" + "&nbsp;" + "&nbsp;" + event;
    this.searchedPetString = event;
    this.isNewPet = false;
   }

   addNewPet() {
    this.isNewPet = true;
    this.appointment.PetName = this.searchedPetString;
   }

   getTypesOfPets() {
    this.incidentService.getTypesOfPets().subscribe(
      (res) => {
        this.petTypes = res["Data"]["PetTypes"];
      },
      (err) => {
        this.petTypes = [];
      }
    );
  }
}
