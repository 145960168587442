import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../services/notifications-http.service';
import { SetupService } from '../../../setup/services/setup-http.service';
import { IncidentService } from '../../../incidents/services/incident-http.service';
import { constants } from '../../../../common/constants';
import { confirmationService } from '../../../../send-notifications/confirmation-modal/confirmation.service';
import { ConfirmationModalComponent } from '../../../../send-notifications/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.css'],
})
export class SendNotificationsComponent implements OnInit {
  @ViewChild('ImageFile')
  myInputVariable: ElementRef;
  public notification: any;
  public currentDivision;
  public selectedOrgId: any;
  public organisationsList: any;
  public selectedNotificationType: any;
  public petParentDropdownSettings;
  public appUserList = [];
  public importFile: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public notificationTypeList = constants.notificationTypeListSendNotifications;
  public userGroupList = constants.userGroupList;
  selectedUserGroup: any;
  public imageTypes = constants.imageTypes;
  public selectedTemplate: any;
  notifiedUsers: any = [];
  attachmentError: string | null = null;
  attachmentFileType: number | null = null;
  data: {};
  selectedOrgName: any;
  private modalRef: BsModalRef;

  maxLengthForDescription: number = 140;
  myControl: any;
  nameFormControl: any;
  textareaErrorMessage: string;
  disableSubmit: boolean;
  noNewLiner: boolean;
  noFourSpace: boolean;
  validatedText: boolean;
  noNewLinerAndFourSpace: boolean;
  newPage: boolean = false;
  messageTemplate: any;
  filteredTemplates: any = [
    {
      Id: 1,
      Name: 'clinic_notification_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 0,
      HasLink: 0,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-01T09:00:00Z',
      ModifiedOn: '2023-06-01T09:00:00Z',
    },
    {
      Id: 2,
      Name: 'clinic_notification_doc_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 0,
      HasLink: 0,
      HasAttachment: 1,
      Status: 1,
      Created: '2023-06-02T10:30:00Z',
      ModifiedOn: '2023-06-02T10:30:00Z',
    },
    {
      Id: 3,
      Name: 'clinic_notification_img_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 1,
      HasLink: 0,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 4,
      Name: 'clinic_notification_doc_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 0,
      HasLink: 1,
      HasAttachment: 1,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 5,
      Name: 'clinic_notification_img_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 1,
      HasLink: 1,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 6,
      Name: 'clinic_notification_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 0,
      HasLink: 1,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
  ];
  whatsappTemplates: any = [
    {
      Id: 1,
      Name: 'clinic_notification_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 0,
      HasLink: 0,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-01T09:00:00Z',
      ModifiedOn: '2023-06-01T09:00:00Z',
    },
    {
      Id: 2,
      Name: 'clinic_notification_doc_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 0,
      HasLink: 0,
      HasAttachment: 1,
      Status: 1,
      Created: '2023-06-02T10:30:00Z',
      ModifiedOn: '2023-06-02T10:30:00Z',
    },
    {
      Id: 3,
      Name: 'clinic_notification_img_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}}',
      HasImage: 1,
      HasLink: 0,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 4,
      Name: 'clinic_notification_doc_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 0,
      HasLink: 1,
      HasAttachment: 1,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 5,
      Name: 'clinic_notification_img_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 1,
      HasLink: 1,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
    {
      Id: 6,
      Name: 'clinic_notification_link_2',
      TemplatePreview:
        'Hello {{1}}, {{2}} wishes to send you the following message: {{3}} Visit: {{4}}',
      HasImage: 0,
      HasLink: 1,
      HasAttachment: 0,
      Status: 1,
      Created: '2023-06-03T14:45:00Z',
      ModifiedOn: '2023-06-03T14:45:00Z',
    },
  ];
  imageURL: any;


  constructor(
    private bsModalRef: BsModalRef,
    private bsModalRefConfirm: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private setupService: SetupService,
    private incidentService: IncidentService,
    private notificationService: NotificationService,
    private confirmationService: confirmationService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.disableSubmit = false;
    this.noNewLinerAndFourSpace = false;
    this.noNewLiner = false;
    this.noFourSpace = false;
    this.selectedUserGroup = this.userGroupList[1].Id;
    this.loadOrgList();
    this.notification = {
      PortalNotificationType: undefined,
      PetParentId: [],
      Description: '',
      ImageType: 0,
      ImageURL: '',
      Link: '',
      UserGroupId: '',
      TemplateId: this.selectedTemplate,
    };
    // TODO: Why are there double loadOrgList function ???
    this.loadOrgList();
    this.getAppUserList(this.selectedOrgId);

    this.petParentDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select all',
      unSelectAllText: 'Unselect all',
      badgeShowLimit: 1,
      text: 'Select pet parent',
      enableSearchFilter: true,
      labelKey: 'itemName',
      searchBy: ['itemName'],
    };
    this.fetchSmsOption()
  }

  clearPetParent(event) {
    this.notifiedUsers = [];
  }

  async assignTemplate(typeId?: any) {
    // console.log("triggered this");
    // console.log(typeId);
    // typeId = parseInt(typeId);
    await this.getWhatsappTemplate();
    const notificationtype = this.notificationTypeList.find(
      (x) => x.Id == this.notification.PortalNotificationType
    )?.Title;
    if (notificationtype === 'WhatsApp') {
      this.messageTemplate = {
        organisation: await this.selectedOrgName.OrganisationName,
        petParent: await this.notifiedUsers[0].itemName,
        Link: await this.notification.Link,
        Attachments: await this.importFile[0],
        imageLink: await this.imageURL,
        notificationType: await notificationtype,
        Description: await this.notification.Description,
      };
    }
    const template = await this.optionSorting();

    if (this.messageTemplate.HasLink === 1) {
      this.messageTemplate.body = template
          .replace('{{1}}', this.messageTemplate.petParent)
          .replace('{{2}}', this.messageTemplate.organisation)
          .replace('{{3}}', this.messageTemplate.Description)
          .replace('{{4}}', this.messageTemplate.Link);
        } else {
          this.messageTemplate.body = template
          .replace('{{1}}', this.messageTemplate.petParent)
          .replace('{{2}}', this.messageTemplate.organisation)
          .replace('{{3}}', this.messageTemplate.Description);
        }
    //     if (typeId !== undefined ) {
    //   this.selectedTemplate = typeId;

    // }
    // console.log(this.selectedTemplate)
  }

  templateParams() {
    if (
      this.notification.Link !== undefined &&
      this.notification.Link !== 'undefined' &&
      this.notification.Link !== ''
    ) {
      this.messageTemplate.HasLink = 1;
    } else {
      this.messageTemplate.HasLink = 0;
    }
    const uploadFile = this.importFile[0];
    if (uploadFile && uploadFile.type.includes('image')) {
      this.messageTemplate.HasImage = 1;
      this.messageTemplate.HasAttachment = 0;
      this.attachmentFileType = 1;
    } else if (uploadFile && !uploadFile.type.includes('image')) {
      this.messageTemplate.HasImage = 0;
      this.messageTemplate.HasAttachment = 1;
      this.attachmentFileType = 2;
    } else {
      this.messageTemplate.HasImage = 0;
      this.messageTemplate.HasAttachment = 0;
      this.attachmentFileType = null;
    }
  }
  optionSorting() {
    this.templateParams();
    if (
      this.messageTemplate.HasAttachment === 0 &&
      this.messageTemplate.HasLink === 0 &&
      this.messageTemplate.HasImage === 0
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 0 && obj.HasAttachment === 0 && obj.HasImage === 0
        );
      });
    }
    if (
      this.messageTemplate.HasAttachment === 0 &&
      this.messageTemplate.HasLink === 1 &&
      this.messageTemplate.HasImage === 0
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 1 && obj.HasAttachment === 0 && obj.HasImage === 0
        );
      });
    }
    if (
      this.messageTemplate.HasAttachment === 0 &&
      this.messageTemplate.HasLink === 0 &&
      this.messageTemplate.HasImage === 1
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 0 && obj.HasAttachment === 0 && obj.HasImage === 1
        );
      });
    }
    if (
      this.messageTemplate.HasAttachment === 1 &&
      this.messageTemplate.HasLink === 0 &&
      this.messageTemplate.HasImage === 0
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 0 && obj.HasAttachment === 1 && obj.HasImage === 0
        );
      });
    }
    if (
      this.messageTemplate.HasAttachment === 1 &&
      this.messageTemplate.HasLink === 1 &&
      this.messageTemplate.HasImage === 0
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 1 && obj.HasAttachment === 1 && obj.HasImage === 0
        );
      });
    }
    if (
      this.messageTemplate.HasAttachment === 0 &&
      this.messageTemplate.HasLink === 1 &&
      this.messageTemplate.HasImage === 1
    ) {
      this.filteredTemplates = this.whatsappTemplates.filter((obj) => {
        return (
          obj.HasLink === 1 && obj.HasAttachment === 0 && obj.HasImage === 1
        );
      });
    }
    this.selectedTemplate = this.filteredTemplates[0].Id;
    // console.log("data: " +this.selectedTemplate);
    // console.log("preview: "+ this.filteredTemplates[0].TemplatePreview)

    return this.filteredTemplates[0].TemplatePreview;
  }

  async getAppUserList(orgId) {
    try {
      const resp = await this.notificationService
        .getAppUserListFast(orgId)
        .toPromise();
      let appUserList = resp['Data']['Users'];

      // For all users group
      if (
        this.notification.UserGroupId === '1' ||
        this.selectedUserGroup === 1
      ) {
        this.appUserList = appUserList;
      }
      // For only mobile app users group
      if (
        this.notification.UserGroupId === '2' ||
        this.selectedUserGroup === 2
      ) {
        this.appUserList = appUserList.filter((item) => {
          if (item.IsAppUser === true) {
            return item;
          }
        });
      }
      // // For only non-app users group
      if (
        this.notification.UserGroupId === '3' ||
        this.selectedUserGroup === 3
      ) {
        this.appUserList = appUserList.filter((item) => {
          if (item.IsAppUser === false) {
            return item;
          }
        });
      }
      this.appUserList = this.appUserList.map((x) => {
        return {
          id: x.Id,
          itemName: x.DisplayName
            ? x.DisplayName
            : x.MobileNumber
            ? x.CountryCode + ' ' + x.MobileNumber
            : '',
        };
      });
      this.notifiedUsers = this.appUserList.slice();
      return this.appUserList;
    } catch (e) {
      if (e.status !== 403) {
        this.toastr.error(
          this.translate.instant('TOASTR.USERS.UNCAUGHT_GET_USER_LIST_ERROR')
        );
      }
    }
    return null;
  }

  close() {
    for(let index in this.notificationTypeList) {
      if(this.notificationTypeList[index].Title == "SMS") {
        this.notificationTypeList.splice(parseInt(index),1)
      }
    }
    this.bsModalRef.hide();
  }

  closeConfirmationPopup() {
    this.bsModalRefConfirm.hide();
  }

  async loadOrgList() {
    this.setupService.getOrganisationsList().subscribe(async (res) => {
      this.organisationsList = res['Data']['Organisations'];
      if (
        this.organisationsList !== null &&
        this.organisationsList.length > 0
      ) {
        this.selectedOrgId = this.organisationsList[0].Id.toString();
        this.selectedOrgName = this.organisationsList[0];
        await this.getAppUserList(this.selectedOrgId);
        console.log(this.selectedOrgId);
      }
    });
  }

  getWhatsappTemplate() {
    this.notificationService.getWhatsappTemplate().subscribe((data) => {
      this.whatsappTemplates = data['Data']['WhatsAppTemplate'];
    });
  }

  onNotificationDropdownChange(notificationTypeId) {
    this.selectedNotificationType = notificationTypeId;
    this.attachmentError = null;
    if (this.importFile.length > 0) this.clearFileInput();
    notificationTypeId == 3
      ? (this.maxLengthForDescription = null)
      : (this.maxLengthForDescription = 140);
  }

  clearFileInput() {
    this.importFile = [];
    this.myInputVariable.nativeElement.value = '';
  }

  onFileSelected(event) {
    // File Preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      console.log(event.target.result);
      this.imageURL = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
    console.log(event.target.value);
    this.importFile = [];
    const fileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      const fileName = fileList[i].name;
      const extension = fileName
        .substring(fileName.lastIndexOf('.') + 1)
        .toLowerCase();
      const isValidImageExtension =
        constants.validImageExtensions.includes(extension);

      // NOTE: whatsapp notfication is planned to handle all kind of valid attachment. Since only pdf file is handled from api end for now commenting this

      // const isValidAudioExtension = constants.validAudioExtensions.includes(extension);
      // const isValidVideoExtension = constants.validVideoExtensions.includes(extension);
      // const isValidFileExtension = constants.validFileExtensions.includes(extension);
      // const isValidAttachment = isValidImageExtension || isValidAudioExtension || isValidVideoExtension || isValidFileExtension;

      //TODO: when we start handling all kind of files other than pdf, this block of code should be replaced with the above implementation
      const validPdfExtensions = ['pdf', 'PDF'].includes(extension);
      const isValidAttachment = isValidImageExtension || validPdfExtensions;

      const isPushNotification = this.selectedNotificationType === '1';
      const isWhatsappNotification = this.selectedNotificationType === '3';

      if (isPushNotification && !isValidImageExtension) {
        this.myInputVariable.nativeElement.value = '';
        this.attachmentError = this.translate.instant(
          'LABELS.Notifications.FORM_ERRORS.NOTIFICATION_ATTACHMENT_ERROR'
        );
      }

      if (
        isWhatsappNotification &&
        isValidAttachment &&
        !isValidImageExtension
      ) {
        this.attachmentFileType = 2;
        this.attachFile(fileList[i]);
      }

      if (isWhatsappNotification && !isValidAttachment) {
        this.myInputVariable.nativeElement.value = '';
        this.attachmentError = this.translate.instant(
          'LABELS.Notifications.FORM_ERRORS.WHATSAPP_ATTACHMENT_ERROR'
        );
      }

      if (
        (isPushNotification && isValidImageExtension) ||
        (isWhatsappNotification && isValidImageExtension)
      ) {
        this.attachmentFileType = 1;
        this.attachFile(fileList[i]);
      }
    }
  }

  onImageTypeChange() {
    if (this.notification.ImageType === 0) {
      this.importFile = [];
    }
  }
  attachFile(file) {
    this.attachmentError = null;
    this.importFile.push(file);
  }

  OnUserGroupChange(userGroupId) {
    this.notification.UserGroupId = userGroupId;
    this.loadOrgList();
  }
  onOrganisationChange(selectedIndex: number) {
    this.selectedOrgName = this.organisationsList[selectedIndex - 1];
  }

  onConfirm() {
    const userType = this.userGroupList.find(
      (x) => x.Id == this.selectedUserGroup
    )?.Title;

    const notificationtype = this.notificationTypeList.find(
      (x) => x.Id == this.notification.PortalNotificationType
    )?.Title;
    this.data = {
      organisation: this.selectedOrgName.OrganisationName,
      Description: this.notification.Description,
      Link: this.notification.Link,
      Attachments: this.importFile,
      notificationType: notificationtype,
      petParent: this.notifiedUsers,
      userType: userType,
    };
    this.confirmationService.formData = this.data;
    this.bsModalRefConfirm = this.modalService.show(
      ConfirmationModalComponent,
      {
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.bsModalRefConfirm.content.event.subscribe((res) => {
      this.sendNotification();
    });
    this.close();
  }
  sendNotification() {
    const notificationPayload = new FormData();
    notificationPayload.append(
      'PortalNotificationType',
      this.notification.PortalNotificationType
    );
    if (this.notifiedUsers !== undefined) {
      if (this.notifiedUsers.length > 0) {
        if (
          Array.isArray(this.notifiedUsers) &&
          this.notifiedUsers.length > 0
        ) {
          const petParentData = this.notifiedUsers;
          const PetParentId = petParentData.map((x) => x.id);
          this.notification.PetParentId = PetParentId;
          notificationPayload.append(
            'petParentId',
            JSON.stringify(this.notification.PetParentId)
          );
        }
      }
    }
    const petparent = this.notification.PetParentId;
    notificationPayload.append('Description', this.notification.Description);
    // if (
    //   this.notification.ImageType &&
    //   this.notification.ImageType !== 0 &&
    //   this.selectedNotificationType !== '4'
    // ) {
    //   notificationPayload.append('ImageType', this.notification.ImageType);
    // }
    // if (
    //   this.notification.ImageType === 2 &&
    //   this.importFile.length !== 0 &&
    //   this.selectedNotificationType !== '4'
    // ) {
    //   for (let i = 0; i < this.importFile.length; i++) {
    //     notificationPayload.append('Files', this.importFile[i]);
    //   }
    // }
    if (this.notification.Link !== '') {
      notificationPayload.append('LinkUrl', this.notification.Link);
    }

    if (this.importFile[0] !== undefined) {
      notificationPayload.append('Attachment', this.importFile[0]);
    }

    if (this.selectedTemplate !== undefined) {
      notificationPayload.append('TemplateId', this.selectedTemplate.toString());
    }

    this.attachmentFileType &&
      notificationPayload.append('FileType', String(this.attachmentFileType));
    this.notificationService
      .sendNotification(notificationPayload, this.selectedOrgId)
      .subscribe(
        (res) => {
          if (res['Status'] === 'Fail' || res['Status'] === 'Error') {
            this.notification.PetParentId = petparent;
            if (
              res['ErrorCode'] === 'REQUESTED_FILETYPE_INVALID' ||
              res['Message'] === 'Upload request need image attachment.'
            ) {
              this.toastr.error(
                this.translate.instant('TOASTR.NOTIFICATIONS.INVALD_IMAGE_TYPE')
              );
            } else if (
              res['Message'] === 'request contains invalid Image Url.'
            ) {
              this.toastr.error(
                this.translate.instant(
                  'TOASTR.NOTIFICATIONS.WRONG_FORMAT_OF_IMG_URL'
                )
              );
            } else {
              this.toastr.error(
                this.translate.instant(
                  'TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_FAIL'
                )
              );
            }
            this.closeConfirmationPopup();
            return;
          }
          this.closeConfirmationPopup();
          this.close();
          this.event.emit();
          this.toastr.success(
            this.translate.instant(
              'TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_SUCCESS'
            )
          );
        },
        (err) => {
          if (err.status !== 403) {
            this.toastr.error(
              this.translate.instant(
                'TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_FAIL'
              )
            );
          }
        }
      );
  }
  onTextAreaInput(event: Event) {
    const textareaValue = (event.target as HTMLTextAreaElement).value;
    if (
      this.selectedNotificationType == 3 &&
      textareaValue.match(/[\r\n\t]/) &&
      textareaValue.match(/ {4}/)
    ) {
      this.noNewLinerAndFourSpace = true;
      this.noNewLiner = false;
      this.noFourSpace = false;
      this.disableSubmit = true;
    } else if (
      this.selectedNotificationType == 3 &&
      textareaValue.match(/[\r\n\t]/)
    ) {
      this.noNewLinerAndFourSpace = false;
      this.disableSubmit = true;
      this.noNewLiner = true;
      this.noFourSpace = false;
    } else if (
      this.selectedNotificationType == 3 &&
      textareaValue.match(/ {4}/)
    ) {
      this.disableSubmit = true;
      this.noNewLinerAndFourSpace = false;
      this.noNewLiner = false;
      this.noFourSpace = true;
    } else {
      this.disableSubmit = false;
      this.noNewLiner = false;
      this.noFourSpace = false;
      this.noNewLinerAndFourSpace = false;
    }
  }
  fetchSmsOption() {
    this.notificationService.getSmsOption().subscribe((res) => {
      if(res['Status'] == "Success") {
        const smsOption = res['Data']['ShowSMS']
        if(smsOption) {
          this.notificationTypeList.push({"Id":2,"Title":"SMS"})
        }
      }
    })
  }
}
