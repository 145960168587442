import { Component, OnInit,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { confirmationService } from './confirmation.service';


@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
 formData : any= {}
 public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private bsModalRef: BsModalRef,
    private confirmService : confirmationService
  ) { }

  ngOnInit(): void {
this.formData =  this.confirmService.formData
this.formData.Attachments.forEach(Attachment => {
})

}
sendNotification(): void {
  this.event.emit();
  this.onCancel()
}
onCancel(){
  this.bsModalRef.hide();
}
}
